<app-header-bienestar [title]="title"></app-header-bienestar>
<div
  class="dashboardContainer"
  [ngStyle]="{ padding: !crearProducto ? '0px 40px 0px 40px' : '0px' }"
  *ngIf="canRender"
>
  <app-navbar-bienestar *ngIf="!crearProducto"></app-navbar-bienestar>

  <!-- <bnr-navbar
    [menu]="menuTest"
    (eventEmitter)="testAction($event)"
  ></bnr-navbar> -->

  <div *ngIf="!crearProducto">
    <div *ngIf="menuSelected == 'principal' || menuSelected == 'tramites'">
      <div class="actions">
        <button
          class="btn btn-outline-secondary action"
          (click)="crearTramite()"
        >
          CREAR NUEVO TRÁMITE
        </button>
        <button class="btn btn-outline-secondary action">
          ACTUALIZACIÓN DE DATOS
        </button>
        <button class="btn btn-outline-secondary action">DOCUMENTOS</button>
      </div>
      <div class="actions-sm">
        <button
          (click)="crearTramite()"
          class="btn-outline-secondary action-sm"
        >
          <i class="fas fa-pen dash-icon"></i><br />
          NUEVO TRÁMITE
        </button>
        <button class="btn-outline-secondary action-sm">
          <i class="fas fa-sync-alt dash-icon"></i><br />
          ACTUALIZACIÓN DE DATOS
        </button>
        <button class="btn-outline-secondary action-sm">
          <i class="fas fa-file dash-icon"></i><br />
          DOCUMENTOS
        </button>
      </div>
      <!-- <div class="search-sm search-container">
        <div style="position: relative">
          <input
            class="form-control"
            placeholder="Buscar..."
            style="margin: 15px auto"
            type="text"
          />
          <i class="fas fa-search"></i>
        </div>
      </div> -->
      <h4 *ngIf="menuSelected == 'principal'">Mis Productos SURA</h4>

      <div *ngIf="menuSelected == 'principal'" class="cardsContainer">
        <div style="margin: 20px" *ngFor="let producto of arrayProductos">
          <app-product-card [product]="producto"></app-product-card>
        </div>
        <div style="margin: 20px">
          <app-product-card
            [type]="'add'"
            (click)="addProduct()"
          ></app-product-card>
        </div>
      </div>
    </div>

    <div *ngIf="menuSelected == 'principal'">
      <h4>Mis Trámites</h4>

      <div class="mt-5 mb-5">
        <app-table-basic
          (action)="action($event)"
          [actions]="buttons"
          [rows]="rows"
          [tableHeaders]="tableHeaders"
          [title]="' '"
          [type]="'tramites'"
          class="table"
          stateType="bienestar"
        ></app-table-basic>

        <!-- <app-table-basic-actions
          (action)="action($event)"
          [actions]="buttons"
          [rows]="rows"
          [tableHeaders]="tableHeaders"
          [title]="' '"
          [type]="'tramites'"
          class="table"
          stateType="bienestar"
          [actionColumn]="actionColumn"
          (eventActionColumn)="eventActionColumn($event)"
        ></app-table-basic-actions> -->
      </div>

      <div class="tableCardsContainer">
        <app-table-card-basic
          [tableHeaders]="tableHeadersMobile"
          [rows]="rows"
          class="cards-container"
          [name]="'grupo1'"
          [type]="'tramites'"
          (action)="action($event)"
        >
        </app-table-card-basic>

        <!-- <app-table-card-basic-actions
          [tableHeaders]="tableHeadersMobile"
          [rows]="rows"
          class="cards-container"
          [name]="'grupo1'"
          [type]="'tramites'"
          (action)="action($event)"
          [actionColumn]="actionColumn"
          (eventActionColumn)="eventActionColumn($event)"
        >
        </app-table-card-basic-actions> -->
      </div>
    </div>

    <!--     <div *ngIf="menuSelected == 'detail'">
      <ol class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs">
          <span (click)="navigateBreadcrumb(breadcrumb)">
            {{ breadcrumb }}
          </span>
          <i class="fas fa-angle-right"></i>
        </li>
      </ol>

      <app-portal-seguimiento
        [folioInput]="detailTramite.numero"
      ></app-portal-seguimiento>
    </div> -->
  </div>

  <div class="empty" *ngIf="crearProducto">
    <div class="left" *ngIf="!polizaEncontrada">
      <div class="title text-bold">
        Comencemos a agregar sus productos para gestionar sus trámites e
        información
      </div>
      <p>
        Por favor ingrese su número de póliza para realizar la búsqueda de su
        información
      </p>

      <form action="" #productForm="ngForm" (ngSubmit)="onSubmit(productForm)">
        <div>
          <input
            ngModel
            #oficina="ngModel"
            type="text"
            class="form-control"
            placeholder="Oficina (*)"
            name="oficina"
            required
            [(ngModel)]="product.oficina"
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
          />
          <small
            *ngIf="!oficina.valid && oficina.touched && oficina.value == ''"
            class="text-danger ml-2"
          >
            Campo obligatorio *</small
          >
        </div>
        <div>
          <input
            ngModel
            type="ramo"
            class="form-control"
            placeholder="Ramo (*)"
            #ramo="ngModel"
            required
            name="ramo"
            [(ngModel)]="product.ramo"
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
          />
          <small
            *ngIf="!ramo.valid && ramo.touched && ramo.value == ''"
            class="text-danger ml-2"
          >
            Campo obligatorio *</small
          >
        </div>
        <div>
          <input
            ngModel
            type="poliza"
            class="form-control"
            placeholder="Póliza (*)"
            #poliza="ngModel"
            required
            name="poliza"
            [(ngModel)]="product.poliza"
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
          />
          <small
            *ngIf="!poliza.valid && poliza.touched && poliza.value == ''"
            class="text-danger ml-2"
          >
            Campo obligatorio *</small
          >
        </div>
        <div></div>

        <div class="skip">
          <a (click)="skip()" class="text-primary link">Omitir</a>
        </div>

        <div class="geosura">
          <div class="info">
            Si tu póliza inicia con "V" (VE, VG, VO, VD) o con “00” favor de dar
            click
            <a
              href="https://geo.sura.com/data/DiligenciarEncuestaF.do?token=6fOYLy2LsF1622674743244"
              class="text-primary link"
              >aquí</a
            >
          </div>
        </div>

        <div
          class="mt-3 bottom"
          [ngStyle]="{
            'justify-content': !hasProducts ? 'center' : 'space-between'
          }"
        >
          <a
            [ngStyle]="{ display: !hasProducts ? 'none' : 'flex' }"
            (click)="back()"
            class="text-primary link"
            >Regresar</a
          >

          <button
            (click)="buscarPoliza(productForm)"
            class="btn btn-primary mt-1"
            type="submit"
            [disabled]="!productForm.valid || apiBusy"
          >
            Continuar
          </button>
        </div>
      </form>
    </div>

    <div class="left" *ngIf="polizaEncontrada">
      <p>
        Hemos encontrado exitosamente su póliza, por favor complete la siguiente
        información para crear su cuenta de usuario
      </p>

      <form action="" #userForm="ngForm" (ngSubmit)="onSubmit(userForm)">
        <div *ngIf="!skipForm">
          <select
            class="form-control"
            (change)="changeTypePerson($event.target.value)"
            [(ngModel)]="typePerson"
            name="typePerson"
          >
            <option value="1">Persona Fisica</option>
            <option value="2">Persona Moral</option>
          </select>
        </div>

        <div *ngIf="typePerson === '1'">
          <div>
            <input
              onkeypress="return ( (event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) )"
              ngModel
              type="text"
              class="form-control"
              placeholder="Primer Nombre de Asegurado (*)"
              [(ngModel)]="user.nombre"
              #nombre="ngModel"
              required
              name="nombre"
            />
            <small
              *ngIf="!nombre.valid && nombre.touched && nombre.value == ''"
              class="text-danger ml-2"
            >
              Campo obligatorio *</small
            >
          </div>

          <div>
            <input
              onkeypress="return ( (event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) )"
              ngModel
              type="text"
              class="form-control"
              placeholder="Segundo Nombre de Asegurado"
              [(ngModel)]="user.segundoNombre"
              #segundoNombre="ngModel"
              name="segundoNombre"
            />
          </div>

          <div>
            <input
              onkeypress="return ( (event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) )"
              ngModel
              type="text"
              class="form-control"
              placeholder="Primer Apellido de Asegurado (*)"
              [(ngModel)]="user.apellido"
              #apellido="ngModel"
              required
              name="apellido"
            />
            <small
              *ngIf="
                !apellido.valid && apellido.touched && apellido.value == ''
              "
              class="text-danger ml-2"
            >
              Campo obligatorio *</small
            >
          </div>

          <div>
            <input
              onkeypress="return ( (event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) )"
              ngModel
              type="text"
              class="form-control"
              placeholder="Segundo Apellido de Asegurado"
              [(ngModel)]="user.segundoApellido"
              #segundoApellido="ngModel"
              name="segundoApellido"
            />
          </div>

          <div>
            <input
              max="{{ today | date: 'yyyy-MM-dd' }}"
              ngModel
              type="text"
              placeholder="Fecha de Nacimiento"
              onfocus="(this.type='date')"
              class="form-control"
              placeholder="Fecha de Nacimiento (*)"
              [(ngModel)]="user.fechaNacimiento"
              #fechaNacimiento="ngModel"
              required
              name="fechaNacimiento"
            />
            <small
              *ngIf="
                !fechaNacimiento.valid &&
                fechaNacimiento.touched &&
                fechaNacimiento.value == ''
              "
              class="text-danger ml-2"
            >
              Campo obligatorio *</small
            >
          </div>
        </div>

        <div *ngIf="typePerson === '2'">
          <div>
            <input
              ngModel
              type="text"
              class="form-control"
              placeholder="RFC (*)"
              #rfcMoral="ngModel"
              [(ngModel)]="user.rfcMoral"
              required
              name="rfcMoral"
            />
            <small
              *ngIf="
                !rfcMoral.valid && rfcMoral.touched && rfcMoral.value == ''
              "
              class="text-danger ml-2"
            >
              Campo obligatorio *</small
            >
          </div>

          <div>
            <input
              ngModel
              type="text"
              class="form-control"
              placeholder="Nombre Comercial"
              #nombreComercial="ngModel"
              name="nombreComercial"
              [(ngModel)]="user.nombreComercial"
            />
          </div>

          <div>
            <input
              ngModel
              type="text"
              class="form-control"
              placeholder="Razón Social (*)"
              #razonSocial="ngModel"
              name="razonSocial"
              [(ngModel)]="user.razonSocial"
              required
            />
          </div>

          <div>
            <input
              max="{{ today | date: 'yyyy-MM-dd' }}"
              ngModel
              type="text"
              onfocus="(this.type='date')"
              class="form-control"
              placeholder="Fecha de Constitución (*)"
              #fechaConsti="ngModel"
              [(ngModel)]="user.fechaConsti"
              required
              name="fechaConsti"
            />
            <small
              *ngIf="
                !fechaConsti.valid &&
                fechaConsti.touched &&
                fechaConsti.value == ''
              "
              class="text-danger ml-2"
            >
              Campo obligatorio *</small
            >
          </div>
        </div>

        <div *ngIf="skipForm">
          <div>
            <input
              ngModel
              type="text"
              class="form-control"
              placeholder="RFC del Contratante"
              #rfcTomador="ngModel"
              name="rfcTomador"
              [(ngModel)]="user.rfcTomador"
            />
          </div>

          <div>
            <input
              onkeypress="return ( (event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) )"
              ngModel
              type="text"
              class="form-control"
              placeholder="Primer Nombre del Contratante"
              #nombreTomador="ngModel"
              [(ngModel)]="user.nombreTomador"
              name="nombreTomador"
              (ngModelChange)="
                validateTomador(
                  nombreTomador.control.value,
                  apellidoTomador.control.value,
                  razonTomador.control.value
                )
              "
            />
          </div>

          <div>
            <input
              onkeypress="return ( (event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) )"
              ngModel
              type="text"
              class="form-control"
              placeholder="Primer Apellido del Contratante"
              #apellidoTomador="ngModel"
              [(ngModel)]="user.apellidoTomador"
              name="apellidoTomador"
              (ngModelChange)="
                validateTomador(
                  nombreTomador.control.value,
                  apellidoTomador.control.value,
                  razonTomador.control.value
                )
              "
            />
          </div>

          <div>
            <input
              onkeypress="return ( (event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) )"
              ngModel
              type="text"
              class="form-control"
              placeholder="Razón Social del Contratante"
              #razonTomador="ngModel"
              [(ngModel)]="user.razonTomador"
              name="razonTomador"
              (ngModelChange)="
                validateTomador(
                  nombreTomador.control.value,
                  apellidoTomador.control.value,
                  razonTomador.control.value
                )
              "
            />
          </div>
        </div>

        <div></div>
        <div class="button-top">
          <div *ngIf="apiBusy" id="loading" class="mr-2"></div>
          <div class="bottom">
            <a (click)="backPoliza()" class="text-primary link">Regresar</a>
            <button
              *ngIf="!skipForm"
              [disabled]="!userForm.valid || apiBusy"
              (click)="agregarProducto(userForm)"
              class="btn btn-primary mt-1"
              type="submit"
            >
              Continuar
            </button>

            <button
              *ngIf="skipForm"
              [disabled]="!userForm.valid || apiBusy || !validTomador"
              (click)="agregarProductoSkip(userForm)"
              class="btn btn-primary mt-1"
              type="submit"
            >
              Continuar
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="right"></div>
  </div>
</div>

<div *ngIf="!canRender" class="loadingContainer">
  <div id="loading"></div>
</div>

<ngx-smart-modal
  #otrasPolizas
  identifier="tipoTramite"
  [customClass]="'nsm-centered'"
  class="text-center modalTramite"
  (onClose)="resetTramite()"
>
  <div class="modalContainer">
    <h2>Seleccione el tipo de trámite que desea realizar</h2>
    <div class="btnContainer">
      <button
        #tramite1
        class="btn btn-outline-secondary action tipo-tramite"
        (click)="switchTramite(tramite1)"
      >
        SEGURO DE VIDA<i class="fas fa-check"></i>
      </button>
      <button
        #tramite2
        class="btn btn-outline-secondary action tipo-tramite"
        (click)="switchTramite(tramite2)"
      >
        SOLICITUD DE REEMBOLSO<i class="fas fa-check"></i>
      </button>
      <button
        #tramite3
        class="btn btn-outline-secondary action tipo-tramite"
        (click)="switchTramite(tramite3)"
      >
        REGISTRO DE PRODUCTOS<i class="fas fa-check"></i>
      </button>
      <button
        #tramite4
        class="btn btn-outline-secondary action tipo-tramite"
        (click)="switchTramite(tramite4)"
      >
        DASHBOARD AGENTE<i class="fas fa-check"></i>
      </button>
      <button
        #tramite5
        class="btn btn-outline-secondary action tipo-tramite"
        (click)="switchTramite(tramite5)"
      >
        DASHBOARD ASEGURADO<i class="fas fa-check"></i>
      </button>
      <button
        #tramite6
        class="btn btn-outline-secondary action tipo-tramite"
        (click)="switchTramite(tramite6)"
      >
        ROTURA DE CRISTALES<i class="fas fa-check"></i>
      </button>
    </div>
    <div class="div-buttons">
      <button
        (click)="continue()"
        [disabled]="!isSelected"
        class="btn btn-primary btn-continue"
      >
        Continuar
      </button>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #errorPoliza
  identifier="errorPoliza"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="modalError">
    <p>Lo sentimos no se pudo comprobar la información ingresada.</p>
    <p>Llame al <span class="text-bold">0800-SAMPLE</span></p>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #errorUser
  identifier="errorUser"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="modalError">
    <p>Lo sentimos no se pudo comprobar la información ingresada.</p>
    <p>Llame al <span class="text-bold">0800-SAMPLE</span></p>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #newProduct
  identifier="newProduct"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="modalError">
    <p>{{ newProductMsg }}</p>
  </div>
</ngx-smart-modal>
