import { ApiBienestarService } from './../../service/api-bienestar.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleTramite } from '../../vida/models/detalle-tramite';
import { Breadcrumb } from 'src/app/shared/breadcrumbs-global/breadcrumb.interface';
import { getFormattedDate } from 'src/functions/common-functions';
import { AppConstants } from 'src/app/constants';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Domicilio } from './interfaces/domicilio';

@Component({
  selector: 'app-detalle-tramite',
  templateUrl: './detalle-tramite.component.html',
  styleUrls: ['./detalle-tramite.component.scss'],
})
export class DetalleTramiteComponent implements OnInit {
  idTramite: string = null;
  module: string = null;
  tipoTramite: string;

  @Input() idTramiteInput: any;

  breadcrumbs: Array<Breadcrumb> = [];
  userMenu;
  dateSolic: string;
  dateTramite: string;
  timeTramite: string;
  amPm: string;
  cuentaBancaria: any;
  domicilio: Domicilio;
  contacto: any;
  documentos: any;

  expanded = false;
  numDocActualizados: any;

  arrayTabs: any[] = [{ tabTitle: 'General', active: true }];
  detalle: DetalleTramite;
  menu = [];
  apiBusy = false;
  agente;

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    public apiBienestar: ApiBienestarService,
    public ngxSmartModalService: NgxSmartModalService
  ) {
    this.menu = JSON.parse(localStorage.getItem('menu'));
  }

  ngOnInit(): void {
    this.breadcrumbs = [
      { label: 'Principal', link: '' },
      { label: 'Mis Trámites', link: '' },
      { label: 'Detalle de Trámite', link: null },
    ];

    this.agente = localStorage.getItem('agenteInfo');

    this.userMenu = JSON.parse(localStorage.getItem('menu'));
    let tramites = this.userMenu.find((z) => z.menu.includes('Trámites'));
    this.breadcrumbs[0].link = '/home';
    this.breadcrumbs[1].link = tramites.routerLink;

    this._route.paramMap.subscribe((params) => {
      this.idTramite = params.get('idTramite');
      this.module = params.get('module');

      let userType = localStorage.getItem('tipo');

      if (!this.module) {
        this.module = 'vida';
      }

      if (this.module === 'afectado') {
        this.module = 'reembolso';
      }

      if (this.module === 'cirugia') {
        this.loadTabsCirugia();
      }
      if (this.module === 'reembolso') {
        this.loadTabsReembolso();
      }

      this.apiBienestar
        .getReclamacionesDetalle(userType, this.module, this.idTramite)
        .subscribe(
          (resp) => {
            console.log(resp);
            //fix Safari
            resp.statusTramite.fecha = resp.statusTramite.fecha.replaceAll(
              ' ',
              'T'
            );
            this.domicilio = resp.domicilio;
            this.detalle = resp;
            this.cuentaBancaria = resp.cuentaBancaria;
            this.contacto = resp.contacto;
            this.tipoTramite = resp.tipoTramite;
            this.documentos = resp.documentosAsegurado;
            this.numDocActualizados = resp.docActualizados;
            this.addNDobj(this.contacto);
            let fecha = this.detalle.fechaSolicitud.split(' ');
            this.dateSolic = getFormattedDate(fecha[0], 'dd/mm/yyyy');
            if (this.module === 'reembolso') {
              this.loadTabsReembolso();
            }
            if (this.module === 'cirugia') {
              this.loadTabsCirugia();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  goBreadcrum(b) {
    if (b == 'Mis trámites') this.router.navigate(['/home']);
  }

  loadTabsReembolso() {
    let agenteInfo = JSON.parse(localStorage.getItem('agenteInfo'));

    this.arrayTabs = [
      { tabTitle: 'General', active: false },
      { tabTitle: 'Facturas', active: true },
      {
        tabTitle: 'Información de pago',
        active: false,
      },
      { tabTitle: 'Contacto', active: false },
      {
        tabTitle: 'Documentación',
        active: false,
        valor: this.numDocActualizados,
      },
    ];
  }

  loadTabsCirugia() {
    this.arrayTabs = [
      { tabTitle: 'General', active: true },
      { tabTitle: 'Contacto', active: false },
      {
        tabTitle: 'Documentación',
        active: false,
        valor: this.numDocActualizados,
      },
    ];
  }

  addNDobj(obj) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '')
        obj[key] = '-';
    }
  }

  tabActualizado(e) {
    this.arrayTabs = e;
    console.log(e);
  }

  generarFiniquito() {
    this.apiBusy = true;
    const url = `${AppConstants.apiBienestar}/v1/monitor/vida/tramites/${this.idTramite}/finiquito`;
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: this.apiBienestar.getToken(),
        Accept: 'application/pdf',
      }),
    }).then((response) => {
      if (response.ok)
        response.blob().then((blob) => {
          this.apiBusy = false;
          var file = new Blob([blob], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = fileURL;
          link.download = fileURL.substr(fileURL.lastIndexOf('/') + 1);
          link.click();
        });
      else {
        this.apiBusy = false;
        this.openModal('errorUploading');
      }
    });
  }

  openModal(modal) {
    this.ngxSmartModalService.getModal(modal).open();
  }

  closeModal(modal) {
    this.ngxSmartModalService.getModal(modal).close();
  }
}
