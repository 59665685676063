<div class="tab-container">
  <div class="time-line-container">
    <div *ngFor="let item of dataTimeLine; let i = index">
      <app-time-line
        [title]="item.dia"
        *ngIf="i + 1 == dataTimeLine.length"
        [last]="true"
      >
        <div
          *ngFor="let state of item.status"
          [ngStyle]="{ background: state.color + '88', color: state.color }"
          class="p-1 pl-3 item-state mb-2"
        >
          {{ state.hora }} - {{ state.nombre }}
        </div>
      </app-time-line>

      <app-time-line [title]="item.dia" *ngIf="i + 1 != dataTimeLine.length">
        <div
          *ngFor="let state of item.status"
          [ngStyle]="{ background: state.color + '88', color: state.color }"
          class="p-1 pl-3 item-state mb-2"
        >
          {{ state.hora }} - {{ state.nombre }}
        </div>
      </app-time-line>
    </div>
  </div>
</div>
