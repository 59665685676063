import { SharedModule } from 'src/app/shared/shared.module';
import { NavbarBienestarComponent } from './navbar-bienestar/navbar-bienestar.component';
import { PortalSeguimientoComponent } from './portal-seguimiento/portal-seguimiento.component';
import { HeaderBienestarComponent } from './header-bienestar/header-bienestar.component';
import { DetalleTramiteComponent } from './detalle-tramite/detalle-tramite.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NavbarComponent } from './navbar/navbar.component';
import { GeneralComponent } from './detalle-tramite/tabs/general/general.component';
import { InfoBancariaComponent } from './detalle-tramite/tabs/info-bancaria/info-bancaria.component';
import { ContactoComponent } from './detalle-tramite/tabs/contacto/contacto.component';
import { FacturasComponent } from './detalle-tramite/tabs/facturas/facturas.component';
import { DocumentacionComponent } from './detalle-tramite/tabs/documentacion/documentacion.component';
import { PrintFiniquitoComponent } from './print-finiquito/print-finiquito.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FormularioAgenteComponent } from './formulario-agente/formulario-agente.component';
import { FormularioDireccionComponent } from './formulario-direccion/formulario-direccion.component';

@NgModule({
  declarations: [
    DetalleTramiteComponent,
    HeaderBienestarComponent,
    PortalSeguimientoComponent,
    NavbarBienestarComponent,
    NavbarComponent,
    GeneralComponent,
    InfoBancariaComponent,
    ContactoComponent,
    FacturasComponent,
    DocumentacionComponent,
    PrintFiniquitoComponent,
    FormularioAgenteComponent,
    FormularioDireccionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxSmartModalModule.forRoot(),
    QRCodeModule,
    ReactiveFormsModule,
  ],
  exports: [
    DetalleTramiteComponent,
    HeaderBienestarComponent,
    PortalSeguimientoComponent,
    NavbarBienestarComponent,
    NavbarComponent,
    GeneralComponent,
    FormularioAgenteComponent,
    FormularioDireccionComponent,
  ],
})
export class SharedBienestarModule {}
