import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiBienestarService } from '../service/api-bienestar.service';

@Component({
  selector: 'app-login-agentes',
  templateUrl: './login-agentes.component.html',
  styleUrls: ['./login-agentes.component.scss'],
})
export class LoginAgentesComponent implements OnInit {
  constructor(
    public apiBienestarService: ApiBienestarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    let tk = localStorage.getItem('tkAgente');

    this.apiBienestarService.tokenAgentes(tk).subscribe((res) => {
      localStorage.setItem('userToken', 'Bearer ' + res.access_token);
      localStorage.setItem('agenteInfo', JSON.stringify(res));
      localStorage.setItem('current_user', JSON.stringify(res));
      this.router.navigate(['/home']);
    });
  }
}
