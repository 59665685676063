import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { getFormattedDate } from './common-functions';

export function fvalidatorLatitud(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let x = parseInt(control.value);

    if (
      (control.value && control.value.toString().trim() == '') ||
      control.value == null ||
      control.value == ''
    )
      return null;

    if (x > 90 || x < -90 || isNaN(x)) {
      console.log(control.parent);
      return { validatorsLatitud: true };
    }
    return null;
  };
}
export function fvalidatorLongitud(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let x = parseInt(control.value);

    if (
      (control.value && control.value.toString().trim() == '') ||
      control.value == null ||
      control.value == ''
    )
      return null;

    if (x > 180 || x < -180 || isNaN(x)) {
      return { validatorsLongitud: true };
    }
    return null;
  };
}

export function validateFisicaRFC(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let x = control.value;

    if (x && x !== '') {
      let letters = x.substring(0, 4);
      let year = x.substring(4, 6);
      let month = x.substring(6, 8);
      let day = x.substring(8, 10);
      let suffix = x.substring(10, 13);

      if (x.length < 10 || x.length > 13) {
        return { validatorsRFC: true };
      }
      if (
        letters.length != 4 ||
        year.length != 2 ||
        month.length != 2 ||
        day.length != 2
      ) {
        return { validatorsRFC: true };
      }
      let res;

      res = /^[a-zA-Z\u00F1\u00D1]+$/.test(letters);
      if (!res) return { validatorsRFC: true };

      res = /^\d+$/.test(year);
      if (!res) return { validatorsRFC: true };

      res = /^\d+$/.test(month);
      if (!res) return { validatorsRFC: true };

      res = /^\d+$/.test(day);
      if (!res) return { validatorsRFC: true };

      if (suffix) res = /^[a-z0-9\u00F1\u00D1]+$/i.test(suffix);
      if (!res) return { validatorsRFC: true };

      if (parseInt(month) > 12 || parseInt(month) < 1) {
        return { validatorsRFC: true };
      }
      if (parseInt(day) > 31 || parseInt(day) < 1) {
        return { validatorsRFC: true };
      }
    }
    return null;
  };
}

export function validateMoralRFC(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let cadena = control.value;
    if (cadena && cadena !== '') {
      if (cadena.length < 9 || cadena.length > 12) {
        return { validatorsRFC: true };
      }

      let letters = cadena.substring(0, 3);
      let year = cadena.substring(3, 5);
      let month = cadena.substring(5, 7);
      let day = cadena.substring(7, 9);
      let suffix = cadena.substring(9, 12);

      if (
        letters.length != 3 ||
        year.length != 2 ||
        month.length != 2 ||
        day.length != 2
      ) {
        return { validatorsRFC: true };
      }

      let res;

      res = /^[a-zA-Z\u00F1\u00D1]+$/.test(letters);
      if (!res) return { validatorsRFC: true };

      res = /^\d+$/.test(year);
      if (!res) return { validatorsRFC: true };

      res = /^\d+$/.test(month);
      if (!res) return { validatorsRFC: true };

      res = /^\d+$/.test(day);
      if (!res) return { validatorsRFC: true };

      if (suffix) res = /^[a-z0-9\u00F1\u00D1]+$/i.test(suffix);
      if (!res) return { validatorsRFC: true };

      if (parseInt(month) > 12 || parseInt(month) < 1) {
        return { validatorsRFC: true };
      }
      if (parseInt(day) > 31 || parseInt(day) < 1) {
        return { validatorsRFC: true };
      }
    }

    return null;
  };
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function menorEdad(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let cadena = control.value;
    if (cadena && cadena !== '') {
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      let fecha = getFormattedDate(cadena, 'yyyy-mm-dd').replaceAll('-', '/');
      var birthDate = new Date(fecha);

      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      if (age >= 18) return null;

      return { menorEdad: true };
    }

    return null;
  };
}

export function minNumberLees(
  firstNumber: string,
  secondNumber: string
): ValidationErrors | null {
  return (ac: AbstractControl) => {
    var firstNum = ac.get(firstNumber)?.value || null;
    var secondNum = ac.get(secondNumber)?.value || null;

    if (firstNum && secondNum) {
      firstNum = parseInt(firstNum);
      secondNum = parseInt(secondNum);

      if (
        firstNum > secondNum ||
        firstNum < 0 ||
        firstNum > 110 ||
        secondNum < 0 ||
        secondNum > 110
      ) {
        return { rangeInvalid: true };
      } else {
        return null;
      }
    }
    return null;
  };
}

export function rangeMonto(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let x = parseInt(control.value);

    if (x < 0 || x > 1500000) {
      return { rangeMonto: true };
    }
    return null;
  };
}

export function rangeDias(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let x = parseInt(control.value);

    if (x < 1 || x > 10000) {
      return { rangeDia: true };
    }
    return null;
  };
}

export function greaterThan(
  firstNumber: string,
  secondNumber: string
): ValidationErrors | null {
  return (ac: AbstractControl) => {
    var firstNum = ac.get(firstNumber)?.value || null;
    var secondNum = ac.get(secondNumber)?.value || null;

    if (firstNum && secondNum) {
      firstNum = parseInt(firstNum);
      secondNum = parseInt(secondNum);

      if (firstNum >= secondNum || firstNum < 0 || secondNum < 0) {
        return { rangeInvalid: true };
      } else {
        return null;
      }
    } else {
      if (firstNum || secondNum) return { rangeInvalid: true };
    }

    return null;
  };
}
