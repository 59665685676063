import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { DatePipe } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SharedBienestarModule } from './shared-bienestar/shared-bienestar.module';
import { Interceptor } from 'src/app/interceptor.service';
import { ErrorComponent } from './error/error.component';
import { LoginAgentesComponent } from './login-agentes/login-agentes.component';

@NgModule({
  declarations: [AppComponent, DashboardComponent, HomeComponent, ErrorComponent, LoginAgentesComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    NgxSmartModalModule.forRoot(),
    SharedBienestarModule,
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
