import { ApiBienestarService } from 'projects/bienestar/src/app/service/api-bienestar.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Status } from 'src/app/shared/file-manager/file-manager.interface';
import { getFormattedDate } from 'src/functions/common-functions';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-documentacion',
  templateUrl: './documentacion.component.html',
  styleUrls: ['./documentacion.component.scss'],
})
export class DocumentacionComponent implements OnInit {
  @Input() documentos;
  @Input() module;
  @Output() updateTab = new EventEmitter<any>();
  arrayDocs: any[] = [];
  valor: number;
  tempValor: any;
  idTramite: any;
  band: boolean;
  docDevuelto: boolean = false;
  arrayTabs: any[] = [];
  numDocActualizados: any;
  apiBusy = false;
  msjError: string;

  constructor(
    public apiBienestar: ApiBienestarService,
    public ngxSmartModalService: NgxSmartModalService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // console.log(this.documentos);
    this.loadData();
  }

  loadData() {
    this.arrayDocs = [];

    var actions;

    this.documentos.forEach((element) => {
      let status =
        element.documentStatusUser.descripcionEstadoUsuario.replaceAll(' ', '');
      var tempDate;
      var cleanDate;
      if (element.fecha) {
        cleanDate = element.fecha.split(' ')[0];
        tempDate = getFormattedDate(cleanDate, 'dd/mm/yyyy');
      }
      let fileInfo = {
        id: element.id,
        name: element.nombreDoc,
        uploaded: tempDate,
        url: element.url,
      };

      if (element.motivoAdicional && status == 'DEVUELTO') {
        actions = {
          motivo: false,
          validar: false,
          ver: false,
          descargar: false,
          expandir: false,
          subir: true,
        };
      } else {
        if (
          status == 'ENPROCESO' ||
          status == 'VALIDADO' ||
          status == Status.CARGADO
        ) {
          actions = {
            motivo: false,
            validar: false,
            ver: false,
            descargar: true,
            expandir: false,
            subir: false,
          };
        } else {
          actions = {
            motivo: false,
            validar: false,
            ver: false,
            descargar: true,
            expandir: false,
            subir: true,
          };
          this.docDevuelto = true;
        }
      }

      this.arrayDocs.push({
        codDoc: element.codDoc,
        fecha: element.fechaDevuelto,
        id: element.id,
        obserAdicional: element.obserAdicional
          ? element.obserAdicional
          : 'No especificado',
        motivoDevolucion: element.motivoDevolucion,
        motivoAdicional: element.motivoAdicional,
        nombreDoc: element.nombreDoc,
        url: element.url,
        status: status,
        colorStatus: element.documentStatusUser.colorEstadoUsuario,
        fileInfo: fileInfo,
        actions: actions,
      });
    });

    this.valor = this.valor - this.tempValor;
    this.tempValor = 0;

    if (this.arrayDocs.length != 0) {
      this.arrayDocs[0].first = true;
    }
  }

  fileManagerEvent(e, index) {
    // console.log(e);
    // console.log(index);
    if (e.action == 'descargar') {
      let data = e.row.fileInfo.url;
      window.open(data, '_blank');
    }

    if (e.action == 'subir') {
      // if (!e.data.type.toString().includes('pdf')) {
      //   this.ngxSmartModalService.getModal('errorUploading').open();
      //   return;
      // }

      this.arrayDocs[index].fileInfo.uploaded = e.data.name;
      this.arrayDocs[index] = e.row;
      this.arrayDocs[index].status = Status.CARGADO;
      this.arrayDocs[index].input = e.data;
      this.band = true;
    }
  }

  openModalDevo() {
    this.apiBusy = true;

    var body: any[] = [];

    this.arrayDocs.forEach((element) => {
      if (element.status == Status.CARGADO) {
        this.valor--;
        this.tempValor = this.valor;
        body.push({
          id: element.fileInfo.id,
          file: element.input,
        });
      }
    });

    // // console.log(body);

    this.apiBienestar.docActualizado(body).subscribe(
      (resp) => {
        // console.log(resp);
        this.ngxSmartModalService.getModal('confirDevolucionReembolso').open();
        this.apiBusy = false;
      },
      (error) => {
        // console.log(error);
        this.msjError = error.error.message;
        this.ngxSmartModalService.getModal('ErrorModal').open();
        this.apiBusy = false;
      },
      () => {
        this._route.paramMap.subscribe((params) => {
          this.idTramite = params.get('idTramite');
        });
        // console.log(this.idTramite);

        let userType = localStorage.getItem('tipo');
        this.apiBienestar
          .getReclamacionesDetalle(userType, 'reembolso', this.idTramite)
          .subscribe(
            (resp) => {
              // console.log(resp);
              this.documentos = resp.documentosAsegurado;
              this.numDocActualizados = resp.docActualizados;
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    );
  }

  closeModalDevo() {
    // this.arrayDocs = []
    // // console.log(this.documentos);
    this.docDevuelto = false;
    this.loadData();

    this.arrayTabs = [
      { tabTitle: 'General', active: false },
      { tabTitle: 'Facturas', active: false },
      { tabTitle: 'Info. Bancaria', active: false },
      { tabTitle: 'Contacto', active: false },
      {
        tabTitle: 'Documentación',
        active: true,
        valor: this.numDocActualizados,
      },
    ];

    if (this.module === 'cirugia')
      this.arrayTabs = [
        { tabTitle: 'General', active: false },
        { tabTitle: 'Contacto', active: false },
        {
          tabTitle: 'Documentación',
          active: true,
          valor: this.numDocActualizados,
        },
      ];

    this.updateTab.emit(this.arrayTabs);
    this.ngxSmartModalService.getModal('confirDevolucionReembolso').close();
  }
}
