import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';
import { cleanObject } from 'src/functions/common-functions';

@Injectable({
  providedIn: 'root',
})
export class ApiAseguradoService {
  _baseURL: string;
  loginURL: string;

  constructor(private http: HttpClient, private datepipe: DatePipe) {
    this._baseURL = AppConstants.apiBienestar;
  }

  getToken() {
    return localStorage.getItem('userToken');
  }

  aseguradoInfo(): Observable<any> {
    const url = `${this._baseURL}/v1/usuario/asegurado`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  aseguradoCheckGMM(): Observable<any> {
    const url = `${this._baseURL}/v1/productos/asegurado/check-servicios-salud`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listadoProductos(tipo): Observable<any> {
    let url;

    if (tipo === 'asegurado')
      url = `${this._baseURL}/v1/productos/asegurado/listado`;

    if (tipo === 'beneficiario')
      url = `${this._baseURL}/v1/productos/beneficiario/listado`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  actualizarProductos(tipo): Observable<any> {
    let url;

    if (tipo === 'asegurado')
      url = `${this._baseURL}/v1/registro-producto/asegurado/actualizar-productos`;

    if (tipo === 'beneficiario')
      url = `${this._baseURL}/v1/registro-producto/beneficiario/actualizar-productos`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  aseguradoDetalle(id, tipo): Observable<any> {
    let url;

    if (tipo === 'asegurado')
      url = `${this._baseURL}/v1/productos/asegurado/detalle/${id}`;

    if (tipo === 'beneficiario')
      url = `${this._baseURL}/v1/productos/beneficiario/detalle/${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  aseguradoGuardarPoliza(producto, tipo): Observable<any> {
    let url;

    if (tipo === 'asegurado')
      url = `${this._baseURL}/v1/registro-producto/asegurado/guardar/poliza`;

    if (tipo === 'beneficiario')
      url = `${this._baseURL}/v1/registro-producto/beneficiario/guardar/poliza`;

    return this.http
      .post(url, producto, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getReclamaciones(tipo): Observable<any> {
    const url = `${this._baseURL}/v1/reclamaciones/${tipo}/listado`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  tramitesDisponibles(tipo): Observable<any> {
    let url;

    if (tipo === 'asegurado')
      url = `${this._baseURL}/v1/productos/asegurado/tramites-disponibles`;

    if (tipo === 'beneficiario')
      url = `${this._baseURL}/v1/productos/beneficiario/tramites-disponibles`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getBeneficiarios(p): Observable<any> {
    const url = `${this._baseURL}/v1/reclamaciones/beneficiario/mis-beneficiarios`;

    let params = cleanObject(p);

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }
}
