<div *ngFor="let finiquito of finiquitos" style="position: relative">
  <div class="print-container" style="margin: 20px 40px">
    <p class="divHeader" style="text-align: center">
      {{ finiquito.campos.nombreArchivo }}
    </p>

    <div class="pusher"></div>

    <div class="membrete">
      <div class="left">
        <p>Seguros SURA, S.A. de C.V.</p>
        <p>Av. Insurgentes Sur #2475, piso 22 y 23</p>
        <p>Barrio Loreto, Delegación Álvaro Obregón</p>
        <p>Código Postal 01090, CDMX.</p>
        <p>Tel. 55-57-23-79-99, 800-723-79-00</p>
      </div>
      <div class="right">
        <img src="assets/img/LogoSURA.svg" alt="" width="150" />
      </div>
    </div>

    <div class="qr-row">
      <div class="title">
        <p class="text-primary">FINIQUITO</p>
        <P class="text-secondary">DE RECLAMACIÓN</P>
      </div>

      <div class="info-finiquito-container">
        <div class="info-finiquito">
          <div class="data">
            <p>Num. de Póliza (Actual)</p>
            <p class="text-bold">{{ finiquito.campos.poliza }}</p>
          </div>
          <div class="data">
            <p>Fecha</p>
            <p class="text-bold">{{ finiquito.campos.fecha }}</p>
          </div>
          <div class="data">
            <p>No. Siniestro</p>
            <p class="text-bold">{{ finiquito.campos.siniestro }}</p>
          </div>
        </div>
      </div>

      <div class="qr">
        <qrcode
          [qrdata]="myAngularxQrCode + finiquito.campos.folio"
          [width]="100"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>
    </div>

    <div class="info-section">
      <div class="info-row">
        <div class="col1">
          <p>Contratante</p>
          <p class="text-bold">{{ finiquito.campos.contratante }}</p>
        </div>
        <div class="col2">
          <p>No. Cerfiticado</p>
          <p class="text-bold">{{ finiquito.campos.certificado }}</p>
        </div>
        <div class="col3">
          <p>Vigencia</p>
          <p class="text-bold">{{ finiquito.campos.vigencia }}</p>
        </div>
        <div class="col4">
          <p>Coaseguro</p>
          <p class="text-bold">{{ finiquito.campos.coaseguro }}%</p>
        </div>
      </div>
      <div class="info-row">
        <div class="col1">
          <p>Asegurado Titular</p>
          <p class="text-bold">{{ finiquito.campos.titular }}</p>
        </div>
        <div class="col2">
          <p>Parentesco</p>
          <p class="text-bold">{{ finiquito.campos.parentesco }}</p>
        </div>
        <div class="col3">
          <p>Suma asegurada</p>
          <p class="text-bold">
            ${{ finiquito.campos.sumaSegurada | number: "1.2-2" }}
          </p>
        </div>
        <div class="col4">
          <p>Deducible</p>
          <p class="text-bold">
            ${{ finiquito.campos.deducible * 1 | number: "1.2-2" }}
          </p>
        </div>
      </div>
      <div class="info-row">
        <div class="col1">
          <p>Asegurado Afectado</p>
          <p class="text-bold">{{ finiquito.campos.afectado }}</p>
        </div>
      </div>
      <div class="accidente-row">
        <div class="col">
          <p>Tipo de Accidente o Enfermedad</p>
          <p class="text-bold">
            {{ finiquito.campos.tipoAccidEnferm }}
          </p>
        </div>

        <div class="col" style="padding-right: 42px">
          <p>CLAVE O.I.I.</p>
          <p class="text-bold">{{ finiquito.campos.claveOII }}</p>
        </div>
      </div>
    </div>

    <div class="cedula-section">
      <div class="up">
        <div class="left">
          <img src="assets/img/cedula.png" alt="" width="260" />
        </div>

        <div class="right">
          <div class="up-internal">
            <p>
              Recibí de Seguros SURA S.A. de C.V. la cantidad mencionada en el
              concepto de "Importe" como pago de la indemnización por la
              presente reclamación formulada en términos de póliza
              correspondiente. Con el pago que recibo a mi entera satisfacción,
              la compañia aseguradora queda liberada de las obligaciones
              derivadas de la presente reclamación.
            </p>

            <div class="lugar">
              <p class="text-bold">{{ finiquito.campos.lugarFecha }}</p>
              <p>Lugar y Fecha</p>
            </div>
          </div>

          <p class="text-bold" style="font-size: 14px">
            Nombre y Firma del Reclamante
          </p>
        </div>
      </div>

      <p class="bottom">
        Estimado Asegurado, le recordamos que los gastos médicos no reembolsados
        por esta póliza, son deducibles para su declaración personal del
        impuesto sobre la renta según artículo 140, fracción II de la propia
        ley, en caso de efectuar dicha reclamación, deberá conservar la copia de
        la liquidación como comprobante para estos efectos, durante diez años
        mínimo.
      </p>
    </div>

    <div class="info-section">
      <div class="info-row">
        <div class="col1">
          <p>Beneficiario</p>
          <p class="text-bold">{{ finiquito.campos.beneficiario }}</p>
        </div>
        <div class="col2">
          <p>Banco</p>
          <p class="text-bold">{{ finiquito.campos.banco }}</p>
        </div>
        <div class="col3">
          <p>Transacción</p>
          <p class="text-bold">{{ finiquito.campos.transaccion }}</p>
        </div>
        <div class="col4">
          <p>Importe</p>
          <p class="text-bold">
            ${{ finiquito.campos.importe | number: "1.2-2" }}
          </p>
        </div>
      </div>
    </div>

    <div class="pagebreak"></div>
    <div class="pusher"></div>

    <div class="headers">
      <div class="th">Concepto</div>
      <div class="th">Reclamado</div>
      <div class="th">Autorizado</div>
      <div class="th">Gastos no cubiertos</div>
      <div class="th">Deducible aplicado</div>
      <div class="th">Coaseguro aplicado</div>
      <div class="th">Pagado</div>
    </div>
    <!-- <div class="info">Folio / Factura # {{ finiquito.campos.folio }}</div> -->

    <div *ngFor="let factura of finiquito.desgloseGastos.facturas">
      <div class="info">Folio / Factura # {{ factura.folioFactura }}</div>

      <div class="row" *ngFor="let row of factura.detalle">
        <div class="col" style="text-align: center">
          {{ row.concepto }}
        </div>
        <div class="col">${{ row.reclamado | number: "1.2-2" }}</div>
        <div class="col">${{ row.autorizado | number: "1.2-2" }}</div>
        <div class="col">${{ row.gastosNoCubiertos | number: "1.2-2" }}</div>
        <div class="col">${{ row.deducible | number: "1.2-2" }}</div>
        <div class="col">${{ row.coaseguro | number: "1.2-2" }}</div>
        <div class="col">${{ row.pagado | number: "1.2-2" }}</div>

        <div class="motivo">
          <p *ngIf="row.motivoRechazo">
            Motivo de rechazo: {{ row.motivoRechazo }}
          </p>
          <p *ngIf="row.observaciones">
            Observaciones: {{ row.observaciones }}
          </p>
        </div>
      </div>
    </div>

    <div class="row row-totales">
      <div class="col" style="text-align: center">Total</div>
      <div class="col">
        ${{ finiquito.desgloseGastos.totales.reclamado | number: "1.2-2" }}
      </div>
      <div class="col">
        ${{ finiquito.desgloseGastos.totales.autorizado | number: "1.2-2" }}
      </div>
      <div class="col">
        ${{
          finiquito.desgloseGastos.totales.gastosNoCubiertos | number: "1.2-2"
        }}
      </div>
      <div class="col">
        ${{ finiquito.desgloseGastos.totales.deducible | number: "1.2-2" }}
      </div>
      <div class="col">
        ${{ finiquito.desgloseGastos.totales.coaseguro | number: "1.2-2" }}
      </div>
      <div class="col">
        ${{ finiquito.desgloseGastos.totales.pagado | number: "1.2-2" }}
      </div>
    </div>

    <div class="observaciones">
      <p class="text-bold">Observaciones Generales</p>
      <p>
        {{ finiquito.campos.observaciones }}
      </p>
    </div>
  </div>

  <div class="divFooter">
    Finiquito generado el {{ finiquito.campos.fecha }}
    {{ finiquito.campos.folio }}
  </div>

  <div class="pagebreak"></div>
</div>
