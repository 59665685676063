import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { PortalSeguimientoComponent } from './shared-bienestar/portal-seguimiento/portal-seguimiento.component';
import { VidaComponent } from './vida/vida.component';
import { DetalleTramiteComponent } from './shared-bienestar/detalle-tramite/detalle-tramite.component';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { LoginAgentesComponent } from './login-agentes/login-agentes.component';
import { PrintFiniquitoComponent } from './shared-bienestar/print-finiquito/print-finiquito.component';

const routes: Routes = [
  {
    path: 'gestion-vida',
    component: VidaComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./vida/vida.module').then((m) => m.VidaModule),
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: 'registro-producto',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./registro-producto/registro-producto.module').then(
            (m) => m.RegistroProductoModule
          ),
      },
    ],
  },

  {
    path: 'registro-producto',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./registro-producto/registro-producto.module').then(
            (m) => m.RegistroProductoModule
          ),
      },
    ],
  },

  {
    path: 'gmm',
    children: [
      {
        path: '',
        loadChildren: () => import('./gmm/gmm.module').then((m) => m.GMMModule),
      },
    ],
  },
  {
    path: 'cristales',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./cristales/cristales.module').then((m) => m.CristalesModule),
      },
    ],
  },

  {
    path: 'dashboard-bienestar',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./dashboard-bienestar/dashboard-bienestar.module').then(
            (m) => m.DashboardBienestarModule
          ),
      },
    ],
  },

  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'login-agente',
    component: LoginAgentesComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'portal-seguimiento',
    component: PortalSeguimientoComponent,
  },

  {
    path: 'portal-seguimiento/:nFolio',
    component: PortalSeguimientoComponent,
  },

  {
    path: 'detalle-tramite',
    component: DetalleTramiteComponent,
  },

  {
    path: 'detalle-tramite/:idTramite',
    component: DetalleTramiteComponent,
  },

  {
    path: 'detalle-tramite/:idTramite/:module',
    component: DetalleTramiteComponent,
  },
  {
    path: 'print-finiquito',
    component: PrintFiniquitoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
