import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiBienestarService } from '../service/api-bienestar.service';
import { AppConstants } from 'src/app/constants';
import { getFormattedDate } from 'src/functions/common-functions';
import { NgForm } from '@angular/forms';
import { ActionTableBasic } from 'src/app/shared/tables/table-basic-actions/action.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  title = 'GESTIÓN DE TRÁMITES';
  tableHeaders: any;
  tableHeadersMobile: any;
  rows: any = [];
  isSelected = false;
  menuSelected = 'principal';
  nuevoTramiteSelected: any;
  detailTramite: any;
  buttons = [
    {
      action: 'detail',
      icon: 'fas fa-search search',
      style: 'btn-option',
    },
  ];
  breadcrumbs: any = [];
  polizaEncontrada: boolean = false;
  crearProducto: boolean = true;
  arrayProductos = [];
  hasProducts: boolean = false;
  oficina: any;
  ramo: any;
  poliza: any;
  apiBusy: any;
  today: any;
  canRender: boolean = false;
  newProductMsg: any;
  skipForm: boolean = false;

  menuTest = [
    {
      label: 'Principal',
      icon: 'fas fa-home',
      action: 'home',
    },
    {
      label: 'Documentos',
      icon: 'fas fa-file',
      action: 'documentos',
    },
    { label: 'Mis Datos', icon: 'fas fa-user', action: 'user' },
  ];

  product = {
    oficina: '',
    ramo: '',
    poliza: '',
  };

  user = {
    nombre: '',
    segundoNombre: '',
    apellido: '',
    segundoApellido: '',
    fechaNacimiento: '',
    rfcMoral: '',
    nombreComercial: '',
    razonSocial: '',
    fechaConsti: '',
    rfcTomador: '',
    nombreTomador: '',
    apellidoTomador: '',
    razonTomador: '',
  };

  validTomador: boolean = false;
  typePerson: string = '1';
  actionColumn: Array<ActionTableBasic> = [];

  @ViewChild('userForm') public userForm: NgForm;
  @ViewChild('productForm') public productForm: NgForm;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: Router,
    private api: ApiBienestarService
  ) {
    this.breadcrumbs = ['Mis trámites', 'Detalle de Trámite'];
    this.apiBusy = false;
    this.today = new Date();

    this.api.getProductos().subscribe(
      (response) => {
        this.arrayProductos = [...response];
        console.log(this.arrayProductos[0]);

        if (this.arrayProductos.length < 1) this.crearProducto = true;
        else {
          this.crearProducto = false;
          this.hasProducts = true;
        }
        this.getTramites();
      },
      (error) => {
        window.location.href = AppConstants.loginURL;
      },
      () => {
        this.canRender = true;
      }
    );
  }

  ngOnInit(): void {
    this.actionColumn.push({
      action: 'descargar',
      color: '#0033a0',
      icon: ' fas fa-download',
      label: 'Descargar',
    });
    this.actionColumn.push({
      action: 'borrar',
      color: '#e4002b',
      icon: 'fas fa-trash-alt',
      label: 'Borrar',
    });
  }

  fillTablePolizas(data) {
    this.tableHeaders = [
      {
        name: 'tipo',
        label: 'Tipo de Trámite',
      },
      {
        name: 'fecha',
        label: 'Fecha de Solicitud',
      },
      {
        name: 'numero',
        label: 'Número de Folio',
      },
      {
        name: 'estado',
        label: 'Estado Actual',
      },
    ];

    this.tableHeadersMobile = [
      {
        name: 'tipo',
        label: 'Tipo de Trámite',
      },
      {
        name: 'fecha',
        label: 'Fecha de Solicitud',
      },
      {
        name: 'numero',
        label: 'Número de Folio',
      },
    ];

    data.forEach((element) => {
      let string = element.fecha;
      let fecha = string.split(' ');

      this.rows.push({
        id: element.id,
        tipo: element.descTramite ? element.descTramite : 'N/A',
        fecha: getFormattedDate(fecha[0], 'dd/mm/yyyy'),
        numero: element.nmsolicitud,
        estado: element.statusNombre,
        modulo: element.modulo,
      });
    });
  }

  selectTramite(tramite: any) {
    // console.log('tratando de cambiar de pantalla');
    this.menuSelected = 'detail';
    this.detailTramite = tramite;

    if (this.detailTramite.modulo == 'cristales')
      this.route.navigate(['detalle-tramite/' + tramite.id + '/cristales']);
    else this.route.navigate(['detalle-tramite/' + tramite.id]);

    console.log(this.detailTramite);
  }

  crearTramite() {
    this.ngxSmartModalService.getModal('tipoTramite').open();
  }

  action(event) {
    switch (event.name) {
      case 'detail':
        this.selectTramite(event.data);
        break;
    }
  }

  switchTramite(tramite) {
    let tramites = document.getElementsByClassName('tipo-tramite');

    for (let index = 0; index < tramites.length; index++) {
      const element = tramites[index];
      element.classList.remove('active');
    }

    this.nuevoTramiteSelected = tramite.textContent;

    tramite.classList.add('active');
    this.isSelected = true;
  }

  resetTramite() {
    let tramites = document.getElementsByClassName('tipo-tramite');

    for (let index = 0; index < tramites.length; index++) {
      const element = tramites[index];
      element.classList.remove('active');
    }

    this.nuevoTramiteSelected = null;
    this.isSelected = false;
  }

  switchMenu(menu, menu2, menuSelected) {
    this.menuSelected = menuSelected;

    let menus = document.getElementsByClassName('menu');

    for (let index = 0; index < menus.length; index++) {
      const element = menus[index];
      element.classList.remove('active');
    }
    menu.classList.add('active');
    menu2.classList.add('active');
  }

  continue() {
    // console.log(this.nuevoTramiteSelected);

    if (this.nuevoTramiteSelected == ' SOLICITUD DE REEMBOLSO') {
      this.route.navigate(['gestion-reembolso']);
    }

    if (this.nuevoTramiteSelected == ' SEGURO DE VIDA') {
      this.route.navigate(['gestion-vida']);
    }

    if (this.nuevoTramiteSelected == ' REGISTRO DE PRODUCTOS') {
      this.route.navigate(['registro-producto']);
    }

    if (this.nuevoTramiteSelected == ' DASHBOARD AGENTE') {
      this.route.navigate(['dashboard-bienestar/agente']);
    }

    if (this.nuevoTramiteSelected == ' DASHBOARD ASEGURADO') {
      this.route.navigate(['dashboard-bienestar/asegurado-beneficiario']);
    }

    if (this.nuevoTramiteSelected == ' ROTURA DE CRISTALES') {
      this.route.navigate(['gestion-cristales']);
    }

    this.ngxSmartModalService.getModal('tipoTramite').close();
  }

  onSubmit(form) {}

  buscarPoliza(f) {
    this.oficina = f.value.oficina;
    this.ramo = f.value.ramo;
    this.poliza = f.value.poliza;

    this.apiBusy = true;

    this.api.searchProduct(this.oficina, this.ramo, this.poliza).subscribe(
      (response) => {
        if (response.message == 'NOK') {
          this.ngxSmartModalService.getModal('errorPoliza').open();
        } else {
          this.polizaEncontrada = true;
        }
        this.apiBusy = false;
      },
      (error) => {
        this.ngxSmartModalService.getModal('errorPoliza').open();
        this.apiBusy = false;
      }
    );
  }

  agregarProducto(f) {
    this.apiBusy = true;

    if (this.typePerson == '1') {
      let nombre1 = f.value.nombre;
      let nombre2 = f.value.segundoNombre;
      let apellido1 = f.value.apellido;
      let apellido2 = f.value.segundoApellido;
      let fechaNacimiento = f.value.fechaNacimiento;

      this.api
        .searchProductFisica(
          this.oficina,
          this.ramo,
          this.poliza,
          '1',
          nombre1,
          nombre2,
          apellido1,
          apellido2,
          getFormattedDate(fechaNacimiento, 'yyyy-mm-dd')
        )
        .subscribe(
          (response) => {
            // console.log(response);

            if (response.message == 'NOK') {
              this.ngxSmartModalService.getModal('errorUser').open();
              this.apiBusy = false;
            } else {
              this.api.getProductos().subscribe((response) => {
                this.arrayProductos = [...response];

                if (this.arrayProductos.length < 1) this.crearProducto = true;
                else {
                  this.crearProducto = false;
                  this.apiBusy = false;
                  this.polizaEncontrada = false;
                }

                this.resetProduct();
                this.resetUser();
              });
            }
          },
          (error) => {
            this.ngxSmartModalService.getModal('errorUser').open();
            this.apiBusy = false;
          }
        );
    }

    if (this.typePerson == '2') {
      let rfcMoral = f.value.rfcMoral;
      let razonSocial = f.value.razonSocial;
      let fechaConsti = f.value.fechaConsti;

      this.api
        .searchProductMoral(
          this.oficina,
          this.ramo,
          this.poliza,
          '2',
          rfcMoral,
          razonSocial,
          getFormattedDate(fechaConsti, 'yyyy-mm-dd')
        )
        .subscribe(
          (response) => {
            // console.log(response);

            if (response.message == 'NOK') {
              this.ngxSmartModalService.getModal('errorUser').open();
              this.apiBusy = false;
            } else {
              this.api.getProductos().subscribe((response) => {
                this.arrayProductos = [...response];

                if (this.arrayProductos.length < 1) this.crearProducto = true;
                else {
                  this.crearProducto = false;
                  this.apiBusy = false;
                  this.polizaEncontrada = false;
                }

                this.resetProduct();
                this.resetUser();
              });
            }
          },
          (error) => {
            this.ngxSmartModalService.getModal('errorUser').open();
            this.apiBusy = false;
          }
        );
    }
  }

  agregarProductoSkip(f) {
    this.apiBusy = true;

    let nombre1 = f.value.nombre;
    let nombre2 = f.value.segundoNombre;
    let apellido1 = f.value.apellido;
    let apellido2 = f.value.segundoApellido;
    let fechaNacimiento = f.value.fechaNacimiento;
    let rfcTo = f.value.rfcTomador;
    let nombreTo = f.value.nombreTomador;
    let apellidoTo = f.value.apellidoTomador;
    let razonSocialTo = f.value.razonTomador;

    this.api
      .searchProductPersonaSkip(
        rfcTo,
        nombreTo,
        apellidoTo,
        razonSocialTo,
        nombre1,
        nombre2,
        apellido1,
        apellido2,
        fechaNacimiento
      )
      .subscribe(
        (response) => {
          if (response.message == 'NOK') {
            this.ngxSmartModalService.getModal('errorUser').open();
            this.apiBusy = false;
          } else {
            this.api.getProductos().subscribe((response) => {
              this.arrayProductos = [...response];

              if (this.arrayProductos.length < 1) this.crearProducto = true;
              else {
                this.crearProducto = false;
                this.apiBusy = false;
                this.polizaEncontrada = false;
              }

              this.resetProduct();
              this.resetUser();
            });
          }
        },
        (error) => {
          this.ngxSmartModalService.getModal('errorUser').open();
          this.apiBusy = false;
        }
      );
  }

  formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0], // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  navigateBreadcrumb(b) {
    if (b === 'Mis trámites') this.menuSelected = 'tramites';
  }

  addProduct() {
    this.crearProducto = true;
  }

  back() {
    this.crearProducto = false;
    this.resetProduct();
  }

  backPoliza() {
    this.polizaEncontrada = false;
    this.skipForm = false;
    // this.typePerson = '1';
  }

  skip() {
    this.polizaEncontrada = true;
    this.skipForm = true;
    this.typePerson = '1';
  }

  validateTomador(nombre, apellido, razon) {
    if (razon != '') {
      this.validTomador = true;
    } else {
      if (nombre != '' && apellido != '') this.validTomador = true;
      else this.validTomador = false;
    }
  }

  changeTypePerson(e) {
    this.typePerson = e;
  }
  getTramites() {
    this.api.getTramites().subscribe(
      (response) => {
        this.fillTablePolizas(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  resetProduct() {
    this.product = {
      oficina: '',
      ramo: '',
      poliza: '',
    };
  }

  resetUser() {
    this.user = {
      nombre: '',
      segundoNombre: '',
      apellido: '',
      segundoApellido: '',
      fechaNacimiento: '',
      rfcMoral: '',
      nombreComercial: '',
      razonSocial: '',
      fechaConsti: '',
      rfcTomador: '',
      nombreTomador: '',
      apellidoTomador: '',
      razonTomador: '',
    };
  }

  testAction(e) {
    console.log(e);
  }

  eventActionColumn(e) {
    console.log(e);
  }
}
