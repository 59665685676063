import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VidaService } from './vida.service';

@Component({
  selector: 'app-vida',
  templateUrl: './vida.component.html',
  styleUrls: ['./vida.component.scss'],
})
export class VidaComponent implements OnInit {
  title = 'GESTIÓN DE TRÁMITES';
  menu = [];
  constructor(
    public vidaService: VidaService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.menu = JSON.parse(localStorage.getItem('menu'));
  }

  ngOnInit(): void {
    this.vidaService.project = 'bienestar';

    if (!this.vidaService.poliza.poliza_seleccionada) {
      this.vidaService.actualStep = 0;
      this.router.navigate(['/gestion-vida/listado-poliza']);
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
