<!-- VISTA VIEJA -->
<div *ngIf="canRender" style="height: 100%">
  <div *ngIf="state == 0" class="portal-container">
    <div class="left">
      <div class="logo">
        <img alt="" src="assets/img/LogoSURA.svg" width="130" />
      </div>
      <div class="form-container">
        <div class="portal-form">
          <div class="special">
            <p class="text-medium text-secondary">
              <span class="text-bold">Bienvenidos</span> al Portal de <br />
              Seguimiento de Trámites de <br />
              <span class="text-bold">Grupo SURA</span>
            </p>
          </div>
          <div class="pt-2" style="margin-top: 40px">
            <form #formFolio="ngForm" style="text-align: center">
              <div class="form-group">
                <input
                  [(ngModel)]="nmFolio"
                  #folio="ngModel"
                  class="form-control"
                  placeholder="Número de Solicitud*"
                  required
                  type="text"
                  name="nmFolio"
                  onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
                />
                <div
                  style="text-align: left"
                  *ngIf="!folio.valid && folio.touched"
                >
                  <small class="text-danger ml-2"> Campo obligatorio *</small>
                </div>
              </div>

              <div class="mt-4">
                <button
                  class="btn btn-primary"
                  (click)="consultar()"
                  [disabled]="!formFolio.valid"
                >
                  CONSULTAR
                </button>
              </div>
            </form>
          </div>
          <div
            class="text-center"
            style="margin-top: 100px"
            (click)="openModal()"
          >
            <a class="text-secondary">¿No encuentras tu número de solicitud?</a>
          </div>
          <div class="text-center"></div>
        </div>
      </div>
    </div>

    <div
      [ngStyle]="{
        background:
          'url(' + backgroundImage + ') no-repeat center center / cover'
      }"
      class="right"
    ></div>
  </div>

  <div *ngIf="state == 1">
    <header *ngIf="!user">
      <div class="logo w-25">
        <img
          src="assets/img/LogoSURA.svg"
          (click)="toHome()"
          alt=""
          width="130"
        />
      </div>
      <div class="w-50 text-center">
        <h3 class="text-secondary">
          {{ title ? title : "SISTEMA DE GESTIÓN DE SINIESTROS" }}
        </h3>
      </div>
      <div class="w-25 form-group"></div>
    </header>
    <!-- <global-header [title]="'SISTEMA DE GESTIÓN DE SINIESTROS'"></global-header> -->
    <navbar-blue [menu]="menu"></navbar-blue>
    <div class="internal-container">
      <div style="text-align: center">
        <h2 class="title-one">Estado de Trámite</h2>
      </div>
      <div class="row">
        <!-- <p class="text-bold text-medium text-danger">RECHAZADO</p> -->
        <p
          class="text-bold text-medium"
          [ngStyle]="{ color: datosPortal?.statusTramite.color }"
        >
          {{ datosPortal?.statusTramite.nombre }}
        </p>
        <p>
          Actualizado al:
          {{ datosPortal?.statusTramite.fecha | date: "dd/MM/yyyy h:mm:ss a" }}
        </p>
      </div>

      <div class="mt-5">
        <app-generic-card
          [info]="{
            icon: 'far fa-check-square',
            text: 'FOLIO/SINIESTRO',
            numero: datosPortal?.folio + nmSiniestro
          }"
        >
          <div style="margin-top: 115px">
            <div class="detalle-solicitud">
              <p class="position-absolute text-bold text-medium">
                {{ datosPortal?.tipoTramite }}, SOLICITADO EL
                {{ datosPortal?.fechaSolicitud | date: "dd/MM/yyyy" }}
              </p>
            </div>

            <div *ngFor="let item of dataTimeLine; let i = index">
              <app-time-line
                [title]="item.dia"
                *ngIf="i + 1 == dataTimeLine.length"
                [last]="true"
              >
                <div
                  *ngFor="let state of item.status"
                  [ngStyle]="{
                    background: state.color + '88',
                    color: state.color
                  }"
                  class="p-1 pl-3 item-state mb-2"
                >
                  {{ state.hora }} - {{ state.nombre }}
                </div>
              </app-time-line>

              <app-time-line
                [title]="item.dia"
                *ngIf="i + 1 != dataTimeLine.length"
              >
                <div
                  *ngFor="let state of item.status"
                  [ngStyle]="{
                    background: state.color + '88',
                    color: state.color
                  }"
                  class="p-1 pl-3 item-state mb-2"
                >
                  {{ state.hora }} - {{ state.nombre }}
                </div>
              </app-time-line>
            </div>
          </div>
        </app-generic-card>
      </div>
    </div>
    <!-- <div class="container-body">
      <h3 style="text-align: center">Seguimiento de Trámite</h3>

      <div class="time-line-container" style="margin-top: 60px">
        <div *ngFor="let item of dataTimeLine; let i = index">
          <app-time-line
            [title]="item[0]"
            *ngIf="i + 1 == dataTimeLine.length"
            [last]="true"
          >
            <div [class]="'pl-5 mb-3 ' + colorLastStatus">
              {{ item[1] }} - {{ item[2] }}
            </div>
          </app-time-line>

          <app-time-line [title]="item[0]" *ngIf="i + 1 != dataTimeLine.length">
            <div class="text-secondary bg-info pl-5 mb-3">
              {{ item[1] }} - {{ item[2] }}
            </div>
          </app-time-line>
        </div>
      </div>

      <div
        class="sidebar"
        style="margin-top: 100px; padding: 30px 10px; visibility: hidden"
      >
        <div class="link">
          <p href="">Actualizar información de contacto</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Conozca el proceso de gestión de su trámite</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Obtener asistencia de un asesor</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Preguntas Frecuentes</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
        <div class="link">
          <p href="">Imprimir Reporte de Trámite</p>
          <i class="fas fa-arrow-right ml-5"></i>
        </div>
      </div>
    </div> -->
  </div>
</div>

<ngx-smart-modal
  #portalModal
  [customClass]="'nsm-centered portalModal'"
  class="text-center"
  identifier="portalModal"
  (onDismiss)="router.navigate(['/portal-seguimiento'])"
  (onClose)="router.navigate(['/portal-seguimiento'])"
>
  <div class="text-medium" style="padding: 50px">
    <p>
      Lo sentimos, puede <br />
      consultar el estado de su <br />
      trámite a través del <br />
      800 008 3693
    </p>
  </div>
  <div>
    <button (click)="closeModal()" class="btn btn-primary">OK</button>
  </div>
</ngx-smart-modal>

<!-- NUEVA VISTA -->

<!-- <div>
  <global-header [title]="'SISTEMA DE GESTIÓN DE SINIESTROS'"></global-header>
  <navbar-blue></navbar-blue>

  <div class="internal-container">
    <div style="text-align: center">
      <h2 class="title-one">Estado de Trámite</h2>
    </div>
    <div class="row">      
      <p
        class="text-bold text-medium"
        [ngStyle]="{ color: datosPortal?.statusTramite.color }"
      >
        {{ datosPortal?.statusTramite.nombre }}
      </p>
      <p>
        Actualizado al:
        {{ datosPortal?.statusTramite.fecha | date: "dd/MM/yyyy h:mm:ss a" }}
      </p>
    </div>

    <div class="mt-5 pad-special">
      <app-generic-card
        [info]="{
          icon: 'far fa-check-square',
          text: 'FOLIO/SINIESTRO',
          numero: datosPortal?.folio + nmSiniestro
        }"
      >
        <div style="margin-top: 115px">
          <div class="detalle-solicitud">
            <p class="position-absolute text-bold text-medium">
              {{ datosPortal?.tipoTramite }}, SOLICITADO EL
              {{ datosPortal?.fechaSolicitud | date: "dd/MM/yyyy" }}
            </p>
          </div>

          <div *ngFor="let item of dataTimeLine; let i = index">
            <app-time-line
              [title]="item.dia"
              *ngIf="i + 1 == dataTimeLine.length"
              [last]="true"
            >
              <div
                *ngFor="let state of item.status"
                [ngStyle]="{
                  background: state.color + '88',
                  color: state.color
                }"
                class="p-1 pl-3 item-state mb-2"
              >
                {{ state.hora }} - {{ state.nombre }}
              </div>
            </app-time-line>

            <app-time-line
              [title]="item.dia"
              *ngIf="i + 1 != dataTimeLine.length"
            >
              <div
                *ngFor="let state of item.status"
                [ngStyle]="{
                  background: state.color + '88',
                  color: state.color
                }"
                class="p-1 pl-3 item-state mb-2"
              >
                {{ state.hora }} - {{ state.nombre }}
              </div>
            </app-time-line>
          </div>
        </div>
      </app-generic-card>
    </div>
  </div>
  <footer-sura></footer-sura>
</div> -->
