import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Status } from 'src/app/shared/file-manager/file-manager.interface';
import { Domicilio } from '../../interfaces/domicilio';

@Component({
  selector: 'app-info-pago',
  templateUrl: './info-bancaria.component.html',
  styleUrls: ['./info-bancaria.component.scss'],
})
export class InfoBancariaComponent implements OnInit {
  @Input() infoBanco: any;
  @Input() domicilio: Domicilio;

  constructor(private datePipe: DatePipe) {}
  tramite: any;
  breadcrumbs = [
    {
      label: 'Principal',
      link: '/cristales/dashboard',
    },
    { label: 'Procesar pago' },
  ];
  arrayDocs = [];

  ngOnInit(): void {
    console.log(
      'Informacion que llega desde el detalle del tramite',
      this.domicilio
    );

    this.infoBanco.archivos.forEach((element) => {
      let doc = {
        status: element.estado.replaceAll(' ', ''),
        colorStatus: element.color,
        fileInfo: {
          name: element.nombreDocumento,
          url: element.url,
          // uploaded: element.fechaSubida,
          uploaded: this.datePipe.transform(
            element.fechaSubida.replace(/\s/g, 'T'),
            'dd/MM/yyyy'
          ),
        },
        actions: {
          motivo: false,
          validar: false,
          ver: false,
          descargar: true,
          expandir: false,
        },
      };
      this.arrayDocs.push(doc);
    });
  }
  fileManagerEvent(e) {
    switch (e.action) {
      case 'descargar':
        let data = e.row.fileInfo.url;
        window.open(data, '_blank');
        break;

      default:
        break;
    }
  }
}
