import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAseguradoService } from '../dashboard-bienestar/asegurado-beneficiario/services/api-asegurado.service';
import { ApiBienestarService } from '../service/api-bienestar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private apiBienestarService: ApiBienestarService,
    private apiAseguradoService: ApiAseguradoService
  ) {}

  ngOnInit(): void {
    this.clearMenu();
    this.apiBienestarService.checkProductos().subscribe(
      (response) => {
        if (response.code === 'OK') {
          this.setTipo(response.data[0]);

          switch (response.data[0]) {
            case 'agente':
              this.setMenuAgente();
              this.router.navigate(['dashboard-bienestar/agente']);
              break;
            case 'afectado':
              this.clearMenu();
              localStorage.removeItem('agenteInfo');
              this.apiAseguradoService
                .getReclamaciones('afectado')
                .subscribe((res) => {
                  if (res.length > 0) {
                    this.setMenuAfectado();
                  }
                  this.router.navigate(['dashboard-bienestar/terceros']);
                });

              break;
            case 'asegurado':
              localStorage.removeItem('agenteInfo');
              this.setMenuBeneficiario();
              this.router.navigate([
                'dashboard-bienestar/asegurado-beneficiario',
              ]);
              break;
            case 'beneficiario':
              localStorage.removeItem('agenteInfo');
              this.setMenuBeneficiario();
              this.router.navigate([
                'dashboard-bienestar/asegurado-beneficiario',
              ]);
              break;
            default:
              localStorage.removeItem('agenteInfo');
              this.router.navigate(['registro-producto']);
              break;
          }
        } else {
          localStorage.removeItem('agenteInfo');
          this.router.navigate(['registro-producto']);
        }
      },
      (error) => {
        console.log(error);
        window.location.href = this.apiBienestarService.loginURL;
      }
    );
  }

  setTipo(tipo) {
    localStorage.setItem('tipo', tipo);
  }

  setMenuAgente() {
    let menu = [
      {
        menu: 'Pólizas y Asegurados',
        routerLink: '/dashboard-bienestar/agente/polizas',
      },
      {
        menu: 'Estado de Trámites',
        routerLink: '/dashboard-bienestar/agente/listado-tramites',
      },
      {
        menu: 'Localizar Servicios de Salud',
        routerLink: null,
      },
    ];

    let menuString = JSON.stringify(menu);
    localStorage.setItem('menu', menuString);
  }

  setMenuBeneficiario() {
    let menu = [
      {
        menu: 'Mis Pólizas',
        routerLink: '/dashboard-bienestar/asegurado-beneficiario/mis-polizas',
      },
      {
        menu: 'Mis Beneficiarios',
        routerLink: '/dashboard-bienestar/asegurado-beneficiario/beneficiarios',
      },
      {
        menu: 'Mis Trámites',
        routerLink: '/dashboard-bienestar/asegurado-beneficiario/mis-tramites',
      },
      {
        menu: 'Localizar Servicios de Salud',
        routerLink: null,
      },
    ];

    let menuString = JSON.stringify(menu);
    localStorage.setItem('menu', menuString);
  }

  setMenuAfectado() {
    let menu = [
      {
        menu: 'Mis Trámites',
        routerLink: '/dashboard-bienestar/terceros/mis-tramites',
      },
      {
        menu: 'Localizar Servicios de Salud',
        routerLink: null,
      },
    ];

    let menuString = JSON.stringify(menu);
    localStorage.setItem('menu', menuString);
  }

  clearMenu() {
    let menu = [];

    let menuString = JSON.stringify(menu);
    localStorage.setItem('menu', menuString);
  }
}
