export class Beneficiario {
  Asegurado: FormBeneficiario;
  ArrayBeneficiarios: Array<FormBeneficiario>;
  isAsegurado: boolean;
  switchAddBeneficiario: boolean;
  beneficiaroSubsecuente: FormBeneficiario;
}

export class FormBeneficiario {
  id: number;
  nombre_uno: string;
  nombre_dos: string;
  apellido_uno: string;
  apellido_dos: string;
  fecha_nacimiento: string;
  sexo: string;
  nacionalidad: string;
  sigla: string;
  numero_interno: string;
  numero_externo: string;
  rfc: string;
  domicilio: string;
  codigoPostal: string;
  pais: string;
  provincia: string;
  municipio: string;
  ciudad: string;
  colonia: string;
  telefono1: string;
  telefono2: string;
  correoElectronico: string;
  tipoPago: string;
  claveInterbancaria: string;
  banco: string;
  porcentaje: string;
  files: any;
  filesloaded: any = {
    file1: false,
    file2: false,
    file3: false,
  };
  localidad: any;
  asegurado: boolean = false;
  cdperson: any;
  ideUrl: string;
  formatoPagoUrl: string;
  edoUrl: string;

  //nuevos
  curp?: string;
  fechaVencimientoIde?: string;
  tipoIdentificacion?: string;
  numeroFolio?: string;
  profesion?: string;
  compDomUrl?: string;
}
