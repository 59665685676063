import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants';
import { cleanObject, getFormattedDate } from 'src/functions/common-functions';

@Injectable({
  providedIn: 'root',
})
export class ApiBienestarService {
  _baseURL: string;
  loginURL: string;
  constructor(private http: HttpClient, private datepipe: DatePipe) {
    this._baseURL = AppConstants.apiBienestar;
  }

  getPolizasPorPersona(fecha, modulo): Observable<any> {
    // const url = `${this._baseURL}/polizas/productos?key=${modulo}&fecha=${fecha}`;

    let rol = localStorage.getItem('tipo');

    let f = getFormattedDate(fecha, 'yyyy-mm-dd');
    const url = `${this._baseURL}/v1/productos/${rol}/ocurrencia?key=${modulo}&fecha=${f}`;
    // const url = `${this._baseURL}/v1/productos/beneficiario/ocurrencia?key=${modulo}&fecha=${f}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getGrupoTramites(cdramo): Observable<any> {
    const url = `${this._baseURL}/grupo-tramites?ramo=${cdramo}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getGrupoTramitesV1(params): Observable<any> {
    const url = `${this._baseURL}/v1/gestor-tramites/vida/tramites`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getSiniestros(
    cdunieco,
    cdramo,
    nmpoliza,
    nmsituac_as,
    causa_clave,
    fecha
  ): Observable<any> {
    let f = getFormattedDate(fecha, 'dd/mm/yyyy');
    const url = `${this._baseURL}/siniestros/subsecuentes?cdunieco=${cdunieco}&cdramo=${cdramo}&nmpoliza=${nmpoliza}&nmsituac_as=${nmsituac_as}&causa_clave=${causa_clave}&fecha=${f}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getBanks(): Observable<any> {
    const url = `${this._baseURL}/catalogos/bancos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getNacionalidades(): Observable<any> {
    const url = `${this._baseURL}/catalogos/nacionalidades`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getSexo(): Observable<any> {
    const url = `${this._baseURL}/catalogos/sexos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getLocalidad(codigoPostal: any): Observable<any> {
    const url = `${this._baseURL}/catalogos/localidad?codigo_postal=${codigoPostal}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getColonias(codigoPostal: any): Observable<any> {
    const url = `${this._baseURL}/catalogos/colonias?codigo_postal=${codigoPostal}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getSiglas(): Observable<any> {
    const url = `${this._baseURL}/catalogos/siglas-domicilio`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getSucursalByBank(bank): Observable<any> {
    const url = `${this._baseURL}/catalogos/sucursales?cdbanco=${bank}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getRFC(
    nombre_uno,
    nombre_dos,
    apellido_uno,
    apellido_dos,
    fecha_nacimiento
  ): Observable<any> {
    'personas/generar-rfc?dsnombre=leonard jose&dsapell1=mendoza&dsapell2=hernandez&fenacimi=04/08/1991';
    const url = `${this._baseURL}/personas/generar-rfc?dsnombre1=${nombre_uno}&dsnombre2=${nombre_dos}&dsapell1=${apellido_uno}&dsapell2=${apellido_dos}&fenacimi=${fecha_nacimiento}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getArchivoSolicitudPago(): Observable<any> {
    const url = `${this._baseURL}/personas/archivo/solicitud-pago`;
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(map((data) => data));
  }
  getSiniestrosReembolso(cdunieco, cdramo, nmpoliza): Observable<any> {
    const url = `${this._baseURL}/siniestros/subsecuentes/reembolso?cdunieco=${cdunieco}&cdramo=${cdramo}&nmpoliza=${nmpoliza}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearTramite(body): Observable<any> {
    const url = `${this._baseURL}/v1/tramites/vida`;

    let f = getFormattedDate(body.feocurre, 'yyyy-mm-dd');
    body.feocurre = f;

    let agenteInfo = JSON.parse(localStorage.getItem('agenteInfo'));
    if (agenteInfo) body.cdAgente = agenteInfo.cdAgente;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getPolizasPorPersonaMulti(fecha, nmsolicitud): Observable<any> {
    // const url = `${this._baseURL}/polizas/multiple?cdperson=${cdperson}&fecha=${fecha}&nmsolicitud=${nmsolicitud}`;

    let fechaTemp = getFormattedDate(fecha, 'yyyy-mm-dd');

    const url = `${this._baseURL}/v1/productos/polizas/multiple?fecha=${fechaTemp}&nmsolicitud=${nmsolicitud}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  multiplesPolizasMonitor(fecha, nmsolicitud): Observable<any> {
    let fechaTemp = getFormattedDate(fecha, 'yyyy-mm-dd');

    const url = `${this._baseURL}/v1/monitor/vida/polizas/multiple?fecha=${fechaTemp}&nmsolicitud=${nmsolicitud}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearBeneficiario(body): Observable<any> {
    // const url = `${this._baseURL}/personas`;
    const url = `${this._baseURL}/v1/personas/vida/fisica`;

    body.fechaNacimiento = getFormattedDate(body.fechaNacimiento, 'yyyy-mm-dd');
    body.fechaVencimientoIde = getFormattedDate(
      body.fechaVencimientoIde,
      'yyyy-mm-dd'
    );

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearContacto(body): Observable<any> {
    // const url = `${this._baseURL}/v1/personas/vida/ct`;
    const url = `${this._baseURL}/v1/personas/vida`;

    body.fechaNacimiento = getFormattedDate(body.fechaNacimiento, 'yyyy-mm-dd');

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearBeneficiarioArchivos(formData): Observable<any> {
    const url = `${this._baseURL}/v1/personas/archivos`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListDocumentos(id_gestor): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/documentos?id_gestor=${id_gestor}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListDocumentosV1(tramiteId): Observable<any> {
    const url = `${this._baseURL}/v1/gestor-tramites/vida/documentos?tramiteId=${tramiteId}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveDocs(file, nFolio, codDoc, archivoIds): Observable<any> {
    let body = new FormData();
    body.append('file', file);
    body.append('numero_solicitud', nFolio);
    body.append('cod_doc', codDoc);

    for (let index = 0; index < archivoIds.length; index++) {
      const element = archivoIds[index];
      body.append('archivo_ids[]', element);
    }

    const url = `${this._baseURL}/gestor-tramites/documentos`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }

  saveDocsNew(
    file,
    reclamacionId,
    codDoc,
    archivoId,
    nombreDoc
  ): Observable<any> {
    let body = new FormData();
    body.append('archivo', file);
    // body.append('file', file);
    body.append('nombreDoc', nombreDoc);
    body.append('reclamacionId', reclamacionId);
    body.append('codDoc', codDoc);
    body.append('archivoId', archivoId);

    // let body = { file, reclamacionId, codDoc, archivoId, nombreDoc };

    // const url = `${this._baseURL}/documentos-reclamacion/vida/subir`;
    const url = `${this._baseURL}/v1/documentos/vida/subir`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearAlea(id): Observable<any> {
    const url = `${this._baseURL}/reportes/crear-alea`;

    var formData = new FormData();
    formData.append('reclamacion_id', id);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearAleaV1(id): Observable<any> {
    const url = `${this._baseURL}/v1/tramites/vida/enviar-alea`;

    var formData = new FormData();
    formData.append('reclamacion_id', id);

    return this.http
      .post(
        url,
        { reclamacionSiniestroId: id },
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  enviarFilenet(id): Observable<any> {
    const url = `${this._baseURL}/v1/tramites/vida/enviar-docs-filenet`;

    return this.http
      .post(
        url,
        { reclamacionSiniestroId: id },
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  procesoSE(reclamacionSiniestroId): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-estandar/vida/procesar-tramite`;

    return this.http
      .post(
        url,
        { reclamacionSiniestroId },
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  setToken(token) {
    localStorage.setItem('userToken', 'Bearer ' + token);
  }

  getToken() {
    return localStorage.getItem('userToken');
  }

  searchProduct(cdunieco, ramo, poliza): Observable<any> {
    const url = `${this._baseURL}/producto/buscar?cdunieco=${cdunieco}&ramo=${ramo}&poliza=${poliza}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  searchProductPersona(
    cdunieco,
    ramo,
    poliza,
    nombre1,
    apellido1,
    apellido2,
    fecha_nacimiento
  ): Observable<any> {
    const url = `${this._baseURL}/producto/buscar/persona?cdunieco=${cdunieco}&ramo=${ramo}&poliza=${poliza}&nombre1=${nombre1}&apellido1=${apellido1}&apellido2=${apellido2}&fecha_nacimiento=${fecha_nacimiento}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  searchProductFisica(
    cdunieco,
    ramo,
    poliza,
    tipoPersona,
    nombre1,
    nombre2,
    apellido1,
    apellido2,
    fechaNacimiento
  ): Observable<any> {
    const url = `${this._baseURL}/producto/buscar/tipo-persona`;

    var formData = new FormData();
    formData.append('tipoPersona', tipoPersona);
    formData.append('cdunieco', cdunieco);
    formData.append('ramo', ramo);
    formData.append('poliza', poliza);
    formData.append('nombre1', nombre1);
    formData.append('nombre2', nombre2);
    formData.append('apellido1', apellido1);
    formData.append('apellido2', apellido2);
    formData.append('fechaNacimiento', fechaNacimiento);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  searchProductMoral(
    cdunieco,
    ramo,
    poliza,
    tipoPersona,
    rfc,
    razonSocial,
    fechaConstitucion
  ): Observable<any> {
    const url = `${this._baseURL}/producto/buscar/tipo-persona`;

    var formData = new FormData();
    formData.append('cdunieco', cdunieco);
    formData.append('ramo', ramo);
    formData.append('poliza', poliza);
    formData.append('tipoPersona', tipoPersona);
    formData.append('rfc', rfc);
    formData.append('razonSocial', razonSocial);
    formData.append('fechaConstitucion', fechaConstitucion);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  searchProductPersonaSkip(
    rfcTo,
    nombreTo,
    apellidoTo,
    razonSocialTo,
    nombre1,
    nombre2,
    apellido1,
    apellido2,
    fechaNacimiento
  ): Observable<any> {
    const url = `${this._baseURL}/producto/buscar/personas`;

    var formData = new FormData();
    formData.append('rfcTo', rfcTo);
    formData.append('nombreTo', nombreTo);
    formData.append('apellidoTo', apellidoTo);
    formData.append('razonSocialTo', razonSocialTo);
    formData.append('nombre1', nombre1);
    formData.append('nombre2', nombre2);
    formData.append('apellido1', apellido1);
    formData.append('apellido2', apellido2);
    formData.append('fechaNacimiento', fechaNacimiento);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getPortal(solicitud): Observable<any> {
    // const url = `${this._baseURL}/tramites/solicitud?nmsolicitud=${solicitud}`;

    const url = `${this._baseURL}/v1/portal-seguimiento/solicitud/${solicitud}`;

    if (this.getToken()) {
      return this.http
        .get(url, {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        })
        .pipe(map((data) => data));
    } else {
      return this.http
        .get(url, {
          headers: new HttpHeaders({
            Accept: '*/*',
          }),
        })
        .pipe(map((data) => data));
    }
  }

  getPortalPrivado(id, module): Observable<any> {
    let url;

    if (module == 'cristales') {
      url = `${this._baseURL}/tramites/cristales/${id}`;
    } else {
      url = `${this._baseURL}/tramites/vida/${id}`;
    }

    if (this.getToken()) {
      return this.http
        .get(url, {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        })
        .pipe(map((data) => data));
    } else {
      return this.http
        .get(url, {
          headers: new HttpHeaders({
            Accept: '*/*',
          }),
        })
        .pipe(map((data) => data));
    }
  }

  verifyToken(token): Observable<any> {
    const url = `${this._baseURL}/auth/user/verificar-token`;

    var formData = new FormData();
    formData.append('refresh_token', token);

    return this.http.post(url, formData).pipe(map((data) => data));
  }

  getUserInfo(token): Observable<any> {
    const url = `${this._baseURL}/auth/user/info`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  setUser(user) {
    let user_string = JSON.stringify(user);
    localStorage.setItem('current_user', user_string);
  }

  getCurrentUser() {
    let user_string = localStorage.getItem('current_user');
    if (user_string) {
      let user = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  getCausasReembolso(): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/perfiles?id_gestor=8`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }

  getDiagnosticoByCausa(perfil_id): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/perfiles/diagnosticos?perfil_id=${perfil_id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }
  getTramiteByCausa(perfil_id): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/tramites?perfil_id=${perfil_id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }

  getProductos(): Observable<any> {
    const url = `${this._baseURL}/producto/lista`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveProduct(
    cdunieco,
    ramo,
    poliza,
    nombre1,
    nombre2,
    apellido1,
    apellido2,
    fecha_nacimiento
  ): Observable<any> {
    const url = `${this._baseURL}/producto/poliza`;

    var formData = new FormData();
    formData.append('cdunieco', cdunieco);
    formData.append('ramo', ramo);
    formData.append('poliza', poliza);
    formData.append('nombre1', nombre1);
    formData.append('nombre2', nombre2);
    formData.append('apellido1', apellido1);
    formData.append('apellido2', apellido2);
    formData.append('fecha_nacimiento', fecha_nacimiento);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  logout(): Observable<any> {
    const url = `${this._baseURL}/auth/user/logout`;
    localStorage.removeItem('current_user');
    return this.http
      .post(url, null, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  siniestrosSubsecuentes(
    cdunieco,
    cdramo,
    aaapertu,
    nmsinies
  ): Observable<any> {
    const url = `${this._baseURL}/siniestros/subsecuentes/personas?cdunieco=${cdunieco}&cdramo=${cdramo}&aaapertu=${aaapertu}&nmsinies=${nmsinies}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getDireccion(poliza, ramo, oficina): Observable<any> {
    const url = `${this._baseURL}/polizas/domicilio/${poliza}/${ramo}/${oficina}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getDireccionV1(poliza, ramo, oficina, nmsituac): Observable<any> {
    const url = `${this._baseURL}/v1/polizas/asegurado/domicilios?oficina=${oficina}&ramo=${ramo}&poliza=${poliza}&nmsituac=${nmsituac}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getDomicilioV1(poliza, ramo, oficina, nmsituac): Observable<any> {
    const url = `${this._baseURL}/v1/polizas/asegurado/domicilios?oficina=${oficina}&ramo=${ramo}&poliza=${poliza}&nmsituac=${nmsituac}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getRespaldos(): Observable<any> {
    const url = `${this._baseURL}/siniestro-cristales/respaldos`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearSiniestroCristales(body): Observable<any> {
    const url = `${this._baseURL}/siniestro-cristales/crear`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearSiniestroCristalesV1(body): Observable<any> {
    const url = `${this._baseURL}/v1/siniestro-cristales/crear`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  searchRfcPersona(rfc, tipo): Observable<any> {
    const url = `${this._baseURL}/personas/busqueda-rfc/${rfc}?tipo_persona=${tipo}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  cuentaBancariaPorPersona(cdPerson): Observable<any> {
    const url = `${this._baseURL}/personas/cuentas-bancarias/${cdPerson}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearPersonaCristales(body): Observable<any> {
    const url = `${this._baseURL}/personas/crear-cristales`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  crearCuentaBanco(body): Observable<any> {
    const url = `${this._baseURL}/personas/cuentas-bancarias`;
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  updateSiniestroCristales(cuentaBancaria, idTramite): Observable<any> {
    const url = `${this._baseURL}/siniestro-cristales/editar/${idTramite}`;
    let body = {
      cuentaBancaria: cuentaBancaria,
    };
    return this.http
      .put(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadFoto(file, cristal_siniestro_id, tipo_foto): Observable<any> {
    let body = new FormData();
    body.append('foto', file);
    body.append('cristal_siniestro_id', cristal_siniestro_id);
    body.append('tipo_foto', tipo_foto);

    const url = `${this._baseURL}/siniestro-cristales/fotos`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadFactura(file, cristal_siniestro_id): Observable<any> {
    let body = new FormData();
    body.append('factura', file);
    body.append('cristal_siniestro_id', cristal_siniestro_id);

    const url = `${this._baseURL}/siniestro-cristales/facturas`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getDocsCristales(): Observable<any> {
    const url = `${this._baseURL}/documentos-cristales/listar`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadDocCristales(
    documentos_cristales_id,
    cristales_siniestro_id,
    file
  ): Observable<any> {
    let body = new FormData();
    body.append('documentos_cristales_id', documentos_cristales_id);
    body.append('cristales_siniestro_id', cristales_siniestro_id);
    body.append('file', file);

    const url = `${this._baseURL}/documentos-siniestros-cristales/subir-documento`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({}),
      })
      .pipe(map((data) => data));
  }

  getAccountsByCdPerson(cdPerson) {
    const url = `${this._baseURL}/personas/cuentas-bancarias/${cdPerson}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  validarFactura(factura): Observable<any> {
    let body = new FormData();
    body.append('factura', factura);

    const url = `${this._baseURL}/v1/facturas/validar`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  archivosPorTramite(tramites): Observable<any> {
    const url = `${this._baseURL}/gestor-tramites/documentos/tramites?tramite_ids=${tramites}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }

  saveTramiteReembolso(tramite): Observable<any> {
    let body = tramite;

    const url = `${this._baseURL}/tramites/reembolso`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  savePersonaReembolso(persona) {
    let body = persona;

    const url = `${this._baseURL}/personas/crear-reembolso`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  updateCuentaBancaria(body): Observable<any> {
    const url = `${this._baseURL}/tramites/actualizar/cuenta-bancaria`;
    return this.http
      .put(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadDocReembolso(
    file,
    reclamacionId,
    nombreDoc,
    archivoIds
  ): Observable<any> {
    let body = new FormData();

    body.append('archivo', file);
    body.append('reclamacionId', reclamacionId);
    body.append('nombreDoc', nombreDoc);

    for (let index = 0; index < archivoIds.length; index++) {
      const element = archivoIds[index];
      body.append('archivoIds[]', element);
    }

    const url = `${this._baseURL}/documentos-reclamacion/reembolso/subir`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadFacturaReembolso(file, reclamacionId): Observable<any> {
    let body = new FormData();
    body.append('factura', file);
    body.append('reclamacionId', reclamacionId);

    const url = `${this._baseURL}/facturas/subir`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  getTramites(): Observable<any> {
    const url = `${this._baseURL}/tramites/mis-tramites`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  generarPdf(id): Observable<any> {
    const url = `${this._baseURL}/siniestro-cristales/generar-pdf/${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getCedula(cedula): Observable<any> {
    const url = `${this._baseURL}/cedula/consulta?cedula_id=${cedula}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }

  getProductosV1(producto, tipo): Observable<any> {
    let url;
    switch (tipo) {
      case 'fisica':
        url = `${this._baseURL}/v1/registro-producto/asegurado/busqueda`;
        producto.fechaNacimiento = getFormattedDate(
          producto.fechaNacimiento,
          'yyyy-mm-dd'
        );
        break;
      case 'moral':
        url = `${this._baseURL}/v1/registro-producto/asegurado/busqueda/moral`;
        producto.fechaConstitucion = getFormattedDate(
          producto.fechaConstitucion,
          'yyyy-mm-dd'
        );
        break;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: cleanObject(producto),
      })
      .pipe(map((data) => data));
  }

  getProductosMultipleV1(producto): Observable<any> {
    console.log(producto);

    const url = `${this._baseURL}/v1/registro-producto/asegurado/busqueda/multiple`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: cleanObject(producto),
      })
      .pipe(map((data) => data));
  }

  saveProductoV1(producto): Observable<any> {
    let url = `${this._baseURL}/v1/registro-producto/asegurado/guardar`;

    if (producto.claveAgente)
      url = `${this._baseURL}/v1/registro-producto/agente/guardar`;

    return this.http
      .post(url, producto, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  asociarPerfilAgente(clave): Observable<any> {
    console.log(clave);

    const url = `${this._baseURL}/v1/registro-producto/agente/busqueda`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: cleanObject(clave),
      })
      .pipe(map((data) => data));
  }

  saveProductoBeneficiario(producto): Observable<any> {
    let url = `${this._baseURL}/v1/registro-producto/beneficiario/poliza/guardar`;

    return this.http
      .post(url, producto, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveProductoBeneficiarioNoInfo(producto): Observable<any> {
    let url = `${this._baseURL}/v1/registro-producto/beneficiario/producto/guardar`;

    producto.fechaNacimientoAs = getFormattedDate(
      producto.fechaNacimientoAs,
      'yyyy-mm-dd'
    );

    producto.fechaSiniestro = getFormattedDate(
      producto.fechaSiniestro,
      'yyyy-mm-dd'
    );

    return this.http
      .post(url, producto, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveProductoAfectado(producto): Observable<any> {
    let url = `${this._baseURL}/v1/registro-producto/afectado/guardar`;

    return this.http
      .post(url, producto, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  checkProductos(): Observable<any> {
    const url = `${this._baseURL}/v1/registro-producto/check`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getAgente(): Observable<any> {
    const url = `${this._baseURL}/v1/usuario/agente`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListadoAgente(): Observable<any> {
    const url = `${this._baseURL}/v1/productos/agente/listado`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListadoTramites(): Observable<any> {
    const { cdAgente } = JSON.parse(localStorage.getItem('current_user'));
    const url = `${this._baseURL}/v1/agentes/reclamaciones?cdAgente=${cdAgente}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  detalleProductoAgente(id): Observable<any> {
    const url = `${this._baseURL}/v1/productos/agente/detalle/${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getAseguradosNombre(filters): Observable<any> {
    console.log(filters);

    const url = `${this._baseURL}/v1/productos/busqueda/asegurado/nombre`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: cleanObject(filters),
      })
      .pipe(map((data) => data));
  }

  getAseguradosCertificado(filters): Observable<any> {
    console.log(filters);

    const url = `${this._baseURL}/v1/productos/busqueda/asegurado/certificado`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: cleanObject(filters),
      })
      .pipe(map((data) => data));
  }

  getAseguradoV1(obj, type): Observable<any> {
    let url;

    if (type == 'fisica')
      url = `${this._baseURL}/v1/monitor/busqueda/cristales/asegurado/fisico`;

    if (type == 'moral')
      url = `${this._baseURL}/v1/monitor/busqueda/cristales/asegurado/moral`;

    delete obj['select'];
    if (obj.fechaOcurrencia)
      obj.fechaOcurrencia = getFormattedDate(obj.fechaOcurrencia, 'yyyy-mm-dd');

    if (obj.fechaNacimiento)
      obj.fechaNacimiento = getFormattedDate(obj.fechaNacimiento, 'yyyy-mm-dd');

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: cleanObject(obj),
      })
      .pipe(map((data) => data));
  }

  getReclamacionesDetalle(type, modulo, id): Observable<any> {
    let url;

    if (type == 'asegurado') {
      url = `${this._baseURL}/v1/reclamaciones/asegurado/detalle/${modulo}/${id}`;
    }

    if (type == 'beneficiario') {
      url = `${this._baseURL}/v1/reclamaciones/beneficiario/detalle/${modulo}/${id}`;
    }

    if (type == 'agente') {
      const { cdAgente } = JSON.parse(localStorage.getItem('current_user'));
      url = `${this._baseURL}/v1/agentes/reclamaciones/detalle/${modulo}/${id}?cdAgente=${cdAgente}`;
    }

    if (type == 'afectado') {
      url = `${this._baseURL}/v1/reclamaciones/asegurado/detalle/${modulo}/${id}`;
    }

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  docActualizado(body): Observable<any> {
    const url = `${this._baseURL}/v1/documentos-reclamaciones/devolucion `;

    var formData = new FormData();

    for (let index = 0; index < body.length; index++) {
      if (body[index].file) {
        let fileKey = 'file[' + index + ']';
        let idKey = 'id[' + index + ']';

        formData.append(fileKey, body[index].file);
        formData.append(idKey, body[index].id);
      }
    }

    // formData.append('valorTotal', body.valorTotal);
    // formData.append('file', body.file);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  detalleFactura(id): Observable<any> {
    const url = `${this._baseURL}/v1/reclamaciones/asegurado/reembolso/tramites/${id}/facturas`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  actualizarFactura(facturaId, factura): Observable<any> {
    let url = `${this._baseURL}/v1/facturas/devuelta`;

    let body = new FormData();
    body.append('facturaId', facturaId);
    body.append('factura', factura);

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listarServiciosSalud(params): Observable<any> {
    const url = `${this._baseURL}/v1/servicios-salud/listar`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getIdentificaciones(): Observable<any> {
    const url = `${this._baseURL}/catalogos/identificaciones`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getProfesiones(): Observable<any> {
    const url = `${this._baseURL}/catalogos/ocupaciones`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  crearCuentaBancaria(obj): Observable<any> {
    const url = `${this._baseURL}/v1/personas/cuentas-bancarias`;

    let body = new FormData();
    body.append('file', obj.file);
    body.append('personaId', obj.personaId);
    body.append('banco', obj.banco);
    body.append('clabe', obj.clabe);

    if (obj.fecha) body.append('fecha', obj.fecha);

    body.append('reclamacionSiniestroId', obj.reclamacionSiniestroId);

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  tokenAgentes(token): Observable<any> {
    const url = `${this._baseURL}/v1/agentes/token`;

    return this.http.post(url, { token }).pipe(map((data) => data));
  }

  descargarFiniquito(id): Observable<any> {
    const url = `${this._baseURL}/v1/reclamaciones/asegurado/detalle/reembolso/${id}/facturas/descargar-finiquito`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getPolizasAgente(obj, type, modulo): Observable<any> {
    let url;

    if (type == 'fisica') url = `${this._baseURL}/v1/agentes/asegurados/fisico`;
    if (type == 'moral') url = `${this._baseURL}/v1/agentes/asegurados/moral`;

    delete obj['select'];
    if (obj.fechaOcurrencia)
      obj.fechaOcurrencia = getFormattedDate(obj.fechaOcurrencia, 'yyyy-mm-dd');

    if (obj.fechaNacimiento)
      obj.fechaNacimiento = getFormattedDate(obj.fechaNacimiento, 'yyyy-mm-dd');

    const cdAgente = JSON.parse(localStorage.getItem('agenteInfo')).cdAgente;

    // const cdAgente = '003742';

    if (!obj.rfc) {
      delete obj['rfc'];
    }

    obj = { ...obj, cdAgente, modulo };

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: obj,
      })
      .pipe(map((data) => data));
  }

  getPolizasAgenteMonitor(obj, type, modulo): Observable<any> {
    let url;

    if (modulo != 'vida') modulo = 'gmm';

    if (type == 'fisica')
      url = `${this._baseURL}/v1/monitor/${modulo}/asegurados/fisico`;
    if (type == 'moral')
      url = `${this._baseURL}/v1/monitor/${modulo}/asegurados/moral`;

    delete obj['select'];
    if (obj.fechaOcurrencia)
      obj.fechaOcurrencia = getFormattedDate(obj.fechaOcurrencia, 'yyyy-mm-dd');

    if (obj.fechaNacimiento)
      obj.fechaNacimiento = getFormattedDate(obj.fechaNacimiento, 'yyyy-mm-dd');

    if (!obj.rfc) {
      delete obj['rfc'];
    }
    // obj = { ...obj, modulo };

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params: obj,
      })
      .pipe(map((data) => data));
  }

  getRamos(modulo): Observable<any> {
    const url = `${this._baseURL}/v1/tramites/ramos?modulo=${modulo}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
