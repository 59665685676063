import { StatusTramite } from './../../../../vida/models/detalle-tramite';
import { forkJoin } from 'rxjs';
import { getFormattedDate } from 'src/functions/common-functions';
import { Tramite } from './../../../../vida/models/tipo-tramite.model';
import { formatCurrency, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiBienestarService } from 'projects/bienestar/src/app/service/api-bienestar.service';
import { ActionTableBasic } from 'src/app/shared/tables/table-basic-actions/action.interface';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss'],
})
export class FacturasComponent implements OnInit {
  rows: any[] = [];
  rowsAux: any[] = [];
  tableHeaders: any;
  noDataError: boolean;
  totalRows;
  facturasDevueltas: boolean = false;
  idFactura;
  motivoDevolucion: string;
  observacionAdicional: string;
  facturasValidadas: any[] = [];
  actionColumn: Array<ActionTableBasic> = [];
  errorFiniquito;

  @Input() idTramite: any;
  @Input() detalle: any;

  detalleFactura: any;
  nestedFilterActive: boolean = true;
  waitingForApi: boolean = false;
  validButtonFactura: boolean = false;
  habilitarFiniquito: boolean = false;
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private apiBienestarService: ApiBienestarService
  ) {}

  ngOnInit(): void {
    registerLocaleData(es);
    console.log(this.idTramite);

    this.tableHeaders = [
      {
        name: 'emisor',
        label: 'RFC Emisor',
      },
      {
        name: 'receptor',
        label: 'RFC Receptor',
      },
      {
        name: 'folio',
        label: 'Folio',
      },
      {
        name: 'fecha',
        label: 'Fecha',
      },
      {
        name: 'requerido',
        label: 'Requerido',
      },
      {
        name: 'autorizado',
        label: 'Autorizado',
      },
    ];

    this.actionColumn.push({
      action: 'descargar',
      color: '#0033a0',
      icon: ' fas fa-download',
      label: 'Descargar',
    });
    this.actionColumn.push({
      action: 'borrar',
      color: '#e4002b',
      icon: 'fas fa-trash-alt',
      label: 'Borrar',
    });

    this.apiBienestarService
      .detalleFactura(this.idTramite)
      .subscribe((resp) => {
        console.log(resp);
        this.detalleFactura = resp;
        this.fillTable(resp);
      });
  }

  action(e) {
    console.log(e);
  }
  getDetalleFactura() {
    // this.apiBienestarService.detalleFactura(this.tramite.id)
  }

  eventActionColumn(e) {
    console.log(e);

    switch (e.action) {
      case 'subir':
        this.idFactura = e.data.id;
        document.getElementById('uploader').click();
        break;

      case 'ver':
        this.motivoDevolucion = e.data.motivoDevolucion;
        this.observacionAdicional = e.data.observacion;
        this.ngxSmartModalService.getModal('motivoModal').open();
        break;

      case 'descargar':
        let data = e.data.xmlUrl;
        window.open(data, '_blank');
        break;

      case 'descargarPDF':
        let dataPDF = e.data.pdfUrl;
        window.open(dataPDF, '_blank');
        break;

      default:
        break;
    }
  }

  setPage(e) {
    // let params;
    // if (this.nestedFilterActive) {
    //   params = {
    //     page: e.offset + 1,
    //     asignado: this.selectedAsignada,
    //     estado: this.selectedEstado,
    //     fechaDesde: this.getDateFrom,
    //     fechaHasta: this.getDateTo,
    //     search: this.buscarString,
    //   };
    // } else {
    //   params = {
    //     page: e.offset + 1,
    //     search: this.buscarString,
    //   };
    // }
    // this.fillTable(params);
  }

  fillTable(params) {
    this.rows = [];
    this.facturasValidadas = [];
    //     autorizado: "NO DISPONIBLE"
    // fecha: "2021-05-05 00:00:00"
    // folio: "7DCBF289-ADF6-11EB-81D0-00155D012007"
    // motivoDevolucion: null
    // observacion: null
    // requerido: 1259138.6
    // rfcEmisor: "EEC081222FH8"
    // rfcReceptor: "R&S811221KR6"

    params.listado.forEach((element) => {
      let fecha = element.fecha.split(' ')[0];
      let actions: any = [];

      if (element.motivoDevolucion) {
        this.facturasDevueltas = true;
        actions.push({
          action: 'ver',
          color: '#00aec7',
          icon: 'fas fa-eye',
          label: 'Ver motivo',
          disabled: false,
        });
      }

      if (element.statusFactura.id == 6) {
        actions.push({
          action: 'subir',
          icon: 'fas fa-folder-open',
          label: 'Subir',
          disabled: false,
        });
      }

      if (element.xmlUrl) {
        actions.push({
          action: 'descargar',
          icon: 'fas fa-download',
          label: 'Descargar',
          disabled: false,
        });
      }

      if (element.pdfUrl) {
        actions.push({
          action: 'descargarPDF',
          icon: 'fas fa-file',
          label: 'Descargar',
          disabled: false,
        });
      }

      this.rows.push({
        emisor: element.rfcEmisor,
        receptor: element.rfcReceptor,
        folio: element.folio,
        fecha: getFormattedDate(fecha, 'dd/mm/yyyy'),
        requerido: '$ ' + formatCurrency(element.requerido, 'es', ''),
        motivoDevolucion: element.motivoDevolucion,
        statusFactura: element.statusFactura,
        observacion: element.observacion,
        autorizado:
          element.autorizado === 'NO DISPONIBLE'
            ? element.autorizado
            : '$' + formatCurrency(element.autorizado, 'es', ''),
        xmlUrl: element.xmlUrl,
        pdfUrl: element.pdfUrl,
        id: element.id,
        estado: {
          color: element.statusFactura.color,
          nombre: element.statusFactura.descripcion,
          id: element.statusFactura.id,
        },
        actions: actions,
      });
    });

    // console.log(this.rows);

    this.totalRows = this.rows.length;

    // this.cristalesServiceService.listSiniestroSupervisor(params).subscribe(
    //   (resp) => {
    //     console.log(resp);
    //     this.totalRows = resp.totalElements;

    //     resp.content.forEach((element) => {
    //       var obj = {
    //         id: element.id,
    //         tipo: element.tipoRespaldo.siglas,
    //         solicitud: element.nmsolicitud,
    //         poliza: element.formatoPoliza,
    //         asegurado: element.asegurado,
    //         fecha: this.datePipe.transform(
    //           element.fecha.replace(/\s/g, 'T'),
    //           'dd/MM/yyyy - hh:mm a'
    //         ),
    //         asignado: element.usuario,
    //         estado: element.statusCristal,
    //         actions: [
    //           {
    //             action: 'asignar',
    //             color: 'blue',
    //             icon: 'fas fa-arrow-circle-right',
    //             label: 'Asignar',
    //             disabled: false,
    //           },
    //           {
    //             action: 'ver',
    //             color: 'black',
    //             icon: 'fas fa-search',
    //             label: 'Ver',
    //             disabled: false,
    //           },
    //           {
    //             action: '',
    //             color: element.semaforo,
    //             icon: 'fas fa-circle',
    //             label: 'Semaforo',
    //             disabled: false,
    //           },
    //         ],
    //       };

    //       if (element.presupuesto) {
    //         obj.actions.unshift({
    //           action: 'descargar',
    //           color: 'blue',
    //           icon: 'fas fa-file',
    //           label: 'Documento',
    //           disabled: false,
    //         });
    //       }

    //       this.rows.push(obj);
    //     });

    //     console.log(this.rows);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    this.validarFacturas();
    this.validFiniquito();
  }

  openModal(name) {
    this.ngxSmartModalService.getModal(name).open();
  }

  closeModal(name) {
    this.ngxSmartModalService.getModal(name).close();
  }

  subirFactura() {
    this.ngxSmartModalService.getModal('confirmModal').open();
    // this.closeModal('confirmModal');
    // document.getElementById('uploader').click();
  }

  facturaActualizada() {
    this.waitingForApi = true;
    this.ngxSmartModalService.getModal('confirmModal').close();

    let arrayPromesas = [];

    for (let index = 0; index < this.facturasValidadas.length; index++) {
      const element = this.facturasValidadas[index];
      arrayPromesas.push(
        this.apiBienestarService.actualizarFactura(
          element.facturaId,
          element.factura
        )
      );
    }

    forkJoin(arrayPromesas).subscribe(
      (array) => {
        console.log(array);
      },
      (error) => {
        console.log(error);
        this.waitingForApi = false;
      },
      () => {
        this.waitingForApi = false;
        this.apiBienestarService
          .detalleFactura(this.idTramite)
          .subscribe((resp) => {
            console.log(resp);
            this.detalleFactura = resp;
            this.fillTable(resp);
          });
        this.ngxSmartModalService.getModal('actualizadasModal').open();
        this.facturasDevueltas = false;
      }
    );
  }

  validarFacturas() {
    console.log(this.rows.some((x) => x.observacion || x.observacion == ''));
    this.validButtonFactura = this.rows.some(
      (x) => x.observacion || x.observacion == ''
    );
  }

  fileChangeEvent(e) {
    if (e[0] == undefined) return;

    let factura = e[0];
    let fileExt = factura.name.split('.').pop();

    if (!fileExt.toString().includes('xml')) {
      this.openModal('errorModal');
      this.errorFiniquito =
        'Extensión de archivo inválida. Por favor verifique.';
      return;
    }

    console.log('Id factura:', this.idFactura);
    console.log(factura);

    this.apiBienestarService.validarFactura(factura).subscribe(
      (resp) => {
        console.log(resp);

        if (resp.valido) {
          this.facturasValidadas.push({
            facturaId: this.idFactura,
            factura: factura,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  validFiniquito() {
    this.rowsAux = [];
    //Copia la row principal a la rowAux
    this.rowsAux = [...this.rows];
    console.log(this.rowsAux.length);
    var aux = [];
    var aux2 = [];

    for (let index = 0; index < this.rowsAux.length; index++) {
      const element = this.rowsAux[index];
      if (element.statusFactura.id != 4) {
        aux.push(element);
      }
    }

    for (let index = 0; index < aux.length; index++) {
      const element = aux[index];
      if (element.statusFactura.id != 3) {
        aux2.push(element);
      }
    }

    console.log(this.rowsAux);

    let valid = aux2.every((x) => x.statusFactura.id == 5);

    if (valid) {
      this.habilitarFiniquito = true;
    } else {
      this.habilitarFiniquito = false;
    }

    if (this.habilitarFiniquito) {
      this.facturasDevueltas = false;
    }
  }

  descargarFiniquito() {
    this.apiBienestarService.descargarFiniquito(this.idTramite).subscribe(
      (res) => {
        this.errorFiniquito = null;
        localStorage.setItem('finiquito', JSON.stringify(res));
        localStorage.setItem('idTramite', this.idTramite);
      },
      (e) => {
        this.errorFiniquito = e.error.message;
        this.openModal('errorModal');
      },
      () => {
        window.open(
          '/print-finiquito?finiquito=true',
          'Print',
          'toolbar=0, resizable=0'
        );
      }
    );
  }
}
