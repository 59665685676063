<div class="tab-container">
  <div class="info-contacto">
    <h2 class="text-medium" style="text-align: center">
      Información de Contacto
    </h2>

    <div class="boxes">
      <div class="info-box">
        <i class="fas fa-phone"></i>
        <div class="info">
          <p class="text-bold">
            {{ contacto?.telefono1 ? contacto.telefono1 : "-" }}
          </p>
          <p>TELÉFONO 1</p>
        </div>
      </div>
      <div class="info-box">
        <i class="fas fa-phone"></i>
        <div class="info">
          <p class="text-bold">
            {{ contacto?.telefono2 ? contacto.telefono2 : "-" }}
          </p>
          <p>TELÉFONO 2</p>
        </div>
      </div>
      <div class="info-box">
        <i class="fas fa-envelope"></i>
        <div class="info">
          <p class="text-bold">{{ contacto?.email ? contacto.email : "-" }}</p>
          <p>CORREO ELECTRÓNICO</p>
        </div>
      </div>

      <div class="info-box">
        <i class="fas fa-envelope"></i>
        <div class="info">
          <p class="text-bold">
            {{ contacto?.email2 ? contacto.email2 : "-" }}
          </p>
          <p>CORREO ELECTRÓNICO 2</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row-label">
    <div>
      <p class="text-bold">{{ contacto?.nombre1 ? contacto.nombre1 : "-" }}</p>
      <p>PRIMER NOMBRE</p>
    </div>
    <div>
      <p class="text-bold">{{ contacto?.nombre2 ? contacto.nombre2 : "-" }}</p>
      <p>SEGUNDO NOMBRE</p>
    </div>
    <div>
      <p class="text-bold">
        {{ contacto?.apellido1 ? contacto.apellido1 : "-" }}
      </p>
      <p>PRIMER APELLIDO</p>
    </div>
    <div>
      <p class="text-bold">
        {{ contacto?.apellido2 ? contacto.apellido2 : "-" }}
      </p>
      <p>SEGUNDO APELLIDO</p>
    </div>
    <!-- <div>
      <p class="text-bold">DD/MM/YYYY</p>
      <p>FECHA DE NACIMIENTO</p>
    </div>
    <div>
      <p class="text-bold">HEGJ820506M10</p>
      <p>RFC</p>
    </div>
    <div>
      <p class="text-bold">ABC</p>
      <p>SIGLAS</p>
    </div> -->
  </div>

  <!-- <div class="row-label">
    <div>
      <p class="text-bold">
        Calle Balderas #27, Centro Histórico. Ciudad de México, Del. Cuauhtémoc,
        C.P. 06040, México
      </p>
      <p>DOMICILIO</p>
    </div>
  </div> -->
</div>
