import { Validaciones } from './models/validacionesPaso4.model';
import { ApiBienestarService } from './../service/api-bienestar.service';
import { Siniestro } from './models/siniestro.model';
import { Router } from '@angular/router';
import { Asegurado } from './models/asegurado.model';
import { Injectable } from '@angular/core';

import { Poliza } from './models/listado-poliza.model';
import { Tramite } from './models/tipo-tramite.model';
import { Beneficiario } from './models/beneficiario.model';
import { StateBar } from './models/state-bar.model';
import { PersonaContacto } from './models/persona-contacto.model';
import { AppConstants } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class VidaService {
  _baseURL: string;
  poliza: Poliza;
  tramite: Tramite;
  asegurado: Asegurado;
  siniestro: Siniestro;
  beneficiario: Beneficiario;
  stateBar: StateBar;
  personaContacto: PersonaContacto;
  formAgente;

  cantSteps: number = 0;
  actualStep: number = 0;
  totalStep: number = 6;
  nmsiniestro: string = null;

  arrayStep: Array<any>;
  bancos: any;
  nacionalidades: any;
  sexo: any;
  siglas: any;
  localidad: any;
  validaciones: Validaciones;

  procesarPoliza = {
    polizasProcesadas: null,
    hasPolizas: false,
    createdTramite: null,
    nFolio: null,
    tramiteId: null,
  };

  tempFiles: any = [];
  tempPoliza: any;

  project;

  constructor(private router: Router, public apiBc: ApiBienestarService) {
    this.project = localStorage.getItem('app') || 'bienestar';

    this.resetService();
  }

  continuar() {
    this.actualStep++;
    if (this.arrayStep[this.actualStep].active == true) {
      this.router.navigate([this.arrayStep[this.actualStep].ruta]);
    } else {
      this.continuar();
    }
  }
  regresar() {
    this.actualStep--;

    if (this.arrayStep[this.actualStep].active == true) {
      this.router.navigate([this.arrayStep[this.actualStep].ruta]);
    } else {
      this.regresar();
    }
  }
  calcularPasos() {
    this.cantSteps = 1;
    for (let index = 0; index < this.arrayStep.length; index++) {
      const element = this.arrayStep[index];
      if (element.active == true) {
        this.cantSteps++;
      } else {
        this.cantSteps--;
      }
    }
  }

  llenarPasoUno() {
    this.poliza.fecha_busqueda = '2018-12-31';

    this.poliza.listado_poliza = [
      {
        titular: 'ENRIQUE ASEGURADO',
        oficina: 10,
        ramo: 901,
        ramo_descripcion: 'VIDA INDIVIDUAL',
        poliza: 8,
        nmpoliza: '10-901-8',
        nmsuplemen: 245815812000000000,
        f_i_vigencia: '08/02/2018',
        f_f_vigencia: '08/02/2019',
        ultimo_pago: null,
        estado_vigencia: 'FUERA VIGENCIA',
        situacion: 0,
        cdperson: 122,
        asegurados: [
          {
            cdperson: 122,
            cdtipide: '1',
            cdideper: 'AEPE880913902',
            nombre: 'ENRIQUE ASEGURADO PEREZ',
            nombre1: 'ENRIQUE',
            nombre2: null,
            apellido1: 'ASEGURADO',
            apellido2: 'PEREZ',
            sexo: 'H',
            fechaNacimiento: '1988-09-13',
            cdprovin: null,
            email: 'TOMADOR@GMAIL.COM',
            email2: null,
            clabe: null,
            telefono: '545555458',
            tipoPago: null,
            nacionalidad: '052',
            emailCliente: null,
            telefonoCliente: null,
            tipoIdentificacion: null,
            numeroFolio: null,
            siglas: 'PI',
            codigoPostal: '90000',
            domicilio: 'PEN',
            telefonoDomicilio: null,
            pais: '052',
            nexterior: '121',
            provincia: '29',
            colonia: '001GX',
            municipio: 'TLAXCALA',
            ciudad: null,
            nmsituac: 1,
            fecha_alta: '08/02/2018',
            fecha_baja: null,
            nsuplogi: null,
            dsparentesco: 'TITULAR',
          },
        ],
      },
      {
        titular: 'ENRIQUE ASEGURADO',
        oficina: 10,
        ramo: 901,
        ramo_descripcion: 'VIDA INDIVIDUAL',
        poliza: 9,
        nmpoliza: '10-901-9',
        nmsuplemen: 245815812000000000,
        f_i_vigencia: '08/02/2018',
        f_f_vigencia: '08/02/2019',
        ultimo_pago: null,
        estado_vigencia: 'FUERA VIGENCIA',
        situacion: 0,
        cdperson: 122,
        asegurados: [
          {
            cdperson: 122,
            cdtipide: '1',
            cdideper: 'AEPE880913902',
            nombre: 'ENRIQUE ASEGURADO PEREZ',
            nombre1: 'ENRIQUE',
            nombre2: null,
            apellido1: 'ASEGURADO',
            apellido2: 'PEREZ',
            sexo: 'H',
            fechaNacimiento: '1988-09-13',
            cdprovin: null,
            email: 'TOMADOR@GMAIL.COM',
            email2: null,
            clabe: null,
            telefono: '545555458',
            tipoPago: null,
            nacionalidad: '052',
            emailCliente: null,
            telefonoCliente: null,
            tipoIdentificacion: null,
            numeroFolio: null,
            siglas: 'PI',
            codigoPostal: '90000',
            domicilio: 'PEN',
            telefonoDomicilio: null,
            pais: '052',
            nexterior: '121',
            provincia: '29',
            colonia: '001GX',
            municipio: 'TLAXCALA',
            ciudad: null,
            nmsituac: 1,
            fecha_alta: '08/02/2018',
            fecha_baja: null,
            nsuplogi: null,
            dsparentesco: 'TITULAR',
          },
        ],
      },
      {
        titular: 'ENRIQUE ASEGURADO',
        oficina: 10,
        ramo: 901,
        ramo_descripcion: 'VIDA INDIVIDUAL',
        poliza: 6,
        nmpoliza: '10-901-6',
        nmsuplemen: 245815712000000000,
        f_i_vigencia: '07/02/2018',
        f_f_vigencia: '07/02/2019',
        ultimo_pago: null,
        estado_vigencia: 'FUERA VIGENCIA',
        situacion: 0,
        cdperson: 122,
        asegurados: [
          {
            cdperson: 122,
            cdtipide: '1',
            cdideper: 'AEPE880913902',
            nombre: 'ENRIQUE ASEGURADO PEREZ',
            nombre1: 'ENRIQUE',
            nombre2: null,
            apellido1: 'ASEGURADO',
            apellido2: 'PEREZ',
            sexo: 'H',
            fechaNacimiento: '1988-09-13',
            cdprovin: null,
            email: 'TOMADOR@GMAIL.COM',
            email2: null,
            clabe: null,
            telefono: '545555458',
            tipoPago: null,
            nacionalidad: '052',
            emailCliente: null,
            telefonoCliente: null,
            tipoIdentificacion: null,
            numeroFolio: null,
            siglas: 'PI',
            codigoPostal: '90000',
            domicilio: 'PEN',
            telefonoDomicilio: null,
            pais: '052',
            nexterior: '121',
            provincia: '29',
            colonia: '001GX',
            municipio: 'TLAXCALA',
            ciudad: null,
            nmsituac: 1,
            fecha_alta: '07/02/2018',
            fecha_baja: null,
            nsuplogi: null,
            dsparentesco: 'TITULAR',
          },
        ],
      },
      {
        titular: 'ENRIQUE ASEGURADO',
        oficina: 10,
        ramo: 901,
        ramo_descripcion: 'VIDA INDIVIDUAL',
        poliza: 7,
        nmpoliza: '10-901-7',
        nmsuplemen: 245815712000000000,
        f_i_vigencia: '07/02/2018',
        f_f_vigencia: '07/02/2019',
        ultimo_pago: null,
        estado_vigencia: 'FUERA VIGENCIA',
        situacion: 0,
        cdperson: 122,
        asegurados: [
          {
            cdperson: 122,
            cdtipide: '1',
            cdideper: 'AEPE880913902',
            nombre: 'ENRIQUE ASEGURADO PEREZ',
            nombre1: 'ENRIQUE',
            nombre2: null,
            apellido1: 'ASEGURADO',
            apellido2: 'PEREZ',
            sexo: 'H',
            fechaNacimiento: '1988-09-13',
            cdprovin: null,
            email: 'TOMADOR@GMAIL.COM',
            email2: null,
            clabe: null,
            telefono: '545555458',
            tipoPago: null,
            nacionalidad: '052',
            emailCliente: null,
            telefonoCliente: null,
            tipoIdentificacion: null,
            numeroFolio: null,
            siglas: 'PI',
            codigoPostal: '90000',
            domicilio: 'PEN',
            telefonoDomicilio: null,
            pais: '052',
            nexterior: '121',
            provincia: '29',
            colonia: '001GX',
            municipio: 'TLAXCALA',
            ciudad: null,
            nmsituac: 1,
            fecha_alta: '07/02/2018',
            fecha_baja: null,
            nsuplogi: null,
            dsparentesco: 'TITULAR',
          },
        ],
      },
    ];

    this.poliza.poliza_seleccionada = {
      titular: 'ENRIQUE ASEGURADO',
      numero: 8,
      nmpoliza: '10-901-8',
      fechaUltimo: '-',
      vigencia: '08/02/2018 - 08/02/2019',
      fechaInicio: '08/02/2018',
      fechaFin: '08/02/2019',
      asegurados: [
        {
          cdperson: 122,
          cdtipide: '1',
          cdideper: 'AEPE880913902',
          nombre: 'ENRIQUE ASEGURADO PEREZ',
          nombre1: 'ENRIQUE',
          nombre2: null,
          apellido1: 'ASEGURADO',
          apellido2: 'PEREZ',
          sexo: 'H',
          fechaNacimiento: '1988-09-13',
          cdprovin: null,
          email: 'TOMADOR@GMAIL.COM',
          email2: null,
          clabe: null,
          telefono: '545555458',
          tipoPago: null,
          nacionalidad: '052',
          emailCliente: null,
          telefonoCliente: null,
          tipoIdentificacion: null,
          numeroFolio: null,
          siglas: 'PI',
          codigoPostal: '90000',
          domicilio: 'PEN',
          telefonoDomicilio: null,
          pais: '052',
          nexterior: '121',
          provincia: '29',
          colonia: '001GX',
          municipio: 'TLAXCALA',
          ciudad: null,
          nmsituac: 1,
          fecha_alta: '08/02/2018',
          fecha_baja: null,
          nsuplogi: null,
          dsparentesco: 'TITULAR',
        },
      ],
      ramo: 901,
      oficina: 10,
      nmsuplemen: 245815812000000000,
      ramoDescripcion: 'VIDA INDIVIDUAL',
      indice: 0,
    };
  }
  llenarPasoDos(tipo) {
    this.tramite.tipos_tramite = [
      {
        id: 1,
        nombre: 'Fallecimiento por enfermedad',
        causa: 'MUERTE',
        causa_cod: '800',
        id_gestor: '1',
        causa_clave: 'TM1',
      },
      {
        id: 2,
        nombre: 'Fallecimiento por accidente',
        causa: 'MUERTE',
        causa_cod: '800',
        id_gestor: '2',
        causa_clave: 'TM1',
      },
      {
        id: 3,
        nombre: 'Pérdida Orgánica o Enfermedad Grave',
        causa: 'PERDIDAS ORGANICAS',
        causa_cod: '802',
        id_gestor: '3',
        causa_clave: 'TP18',
      },
      {
        id: 4,
        nombre: 'Invalidez total y/o permanente',
        causa: 'NVALIDEZ TOTAL Y PERMANENTE',
        causa_cod: '805',
        id_gestor: '4',
        causa_clave: 'TI19',
      },
      {
        id: 5,
        nombre: 'Desempleo o incapacidad temporal',
        causa: 'INDEMNIZACION DIARIA POR INVALIDEZ',
        causa_cod: '803',
        id_gestor: '5',
        causa_clave: 'TI1',
      },
      {
        id: 6,
        nombre: 'Dotal / Supervivencia',
        causa: 'OTRA CAUSA',
        causa_cod: '799',
        id_gestor: '6',
        causa_clave: 'TD10',
      },
      {
        id: 7,
        nombre: 'Recuperación',
        causa: 'OTRA CAUSA',
        causa_cod: '799',
        id_gestor: '7',
        causa_clave: 'TR15',
      },
    ];

    switch (tipo) {
      case 1:
        this.tramite.tramite_seleccionado = {
          id: 1,
          nombre: 'Fallecimiento por enfermedad',
          causa: 'MUERTE',
          causa_cod: '800',
          id_gestor: '1',
          causa_clave: 'TM1',
        };

        this.seleccionarTramite(tipo);
        break;
      case 2:
        this.tramite.tramite_seleccionado = {
          id: 2,
          nombre: 'Fallecimiento por accidente',
          causa: 'MUERTE',
          causa_cod: '800',
          id_gestor: '2',
          causa_clave: 'TM1',
        };
        this.seleccionarTramite(tipo);
        break;
      case 3:
        this.tramite.tramite_seleccionado = {
          id: 3,
          nombre: 'Pérdida Orgánica o Enfermedad Grave',
          causa: 'PERDIDAS ORGANICAS',
          causa_cod: '802',
          id_gestor: '3',
          causa_clave: 'TP18',
        };
        this.seleccionarTramite(tipo);
        break;
      case 4:
        this.tramite.tramite_seleccionado = {
          id: 4,
          nombre: 'Invalidez total y/o permanente',
          causa: 'NVALIDEZ TOTAL Y PERMANENTE',
          causa_cod: '805',
          id_gestor: '4',
          causa_clave: 'TI19',
        };
        this.seleccionarTramite(tipo);
        break;
      case 5:
        this.tramite.tramite_seleccionado = {
          id: 5,
          nombre: 'Desempleo o incapacidad temporal',
          causa: 'INDEMNIZACION DIARIA POR INVALIDEZ',
          causa_cod: '803',
          id_gestor: '5',
          causa_clave: 'TI1',
        };
        this.seleccionarTramite(tipo);
        break;
      case 6:
        this.tramite.tramite_seleccionado = {
          id: 6,
          nombre: 'Dotal / Supervivencia',
          causa: 'OTRA CAUSA',
          causa_cod: '799',
          id_gestor: '6',
          causa_clave: 'TD10',
        };
        this.seleccionarTramite(tipo);
        break;
      case 7:
        this.tramite.tramite_seleccionado = {
          id: 7,
          nombre: 'Recuperación',
          causa: 'OTRA CAUSA',
          causa_cod: '799',
          id_gestor: '7',
          causa_clave: 'TR15',
        };
        this.seleccionarTramite(tipo);
        break;

      default:
        break;
    }
  }
  llenarPasoTres() {
    this.asegurado.listado_asegurados = [
      {
        indice: 0,
        fechaAlta: '08/02/2018',
        apellido1: 'ASEGURADO',
        apellido2: 'PEREZ',
        cdideper: 'AEPE880913902',
        cdperson: 122,
        cdprovin: null,
        cdtipide: '1',
        ciudad: null,
        clabe: null,
        codigoPostal: '90000',
        colonia: '001GX',
        domicilio: 'PEN',
        dsparentesco: 'TITULAR',
        email: 'TOMADOR@GMAIL.COM',
        email2: null,
        emailCliente: null,
        fechaNacimiento: '13/09/1988',
        fechaBaja: null,
        nacionalidad: '052',
        nexterior: '121',
        nmsituac: 1,
        nombre: 'ENRIQUE ASEGURADO PEREZ',
        nombre1: 'ENRIQUE',
        nombre2: null,
        nsuplogi: null,
        numeroFolio: null,
        pais: '052',
        provincia: '29',
        sexo: 'H',
        siglas: 'PI',
        telefono: '545555458',
        telefonoCliente: null,
        telefonoDomicilio: null,
        tipoIdentificacion: null,
        tipoPago: null,
      },
    ];
    this.asegurado.asegurado_seleccionado = {
      indice: 0,
      fechaAlta: '08/02/2018',
      apellido1: 'ASEGURADO',
      apellido2: 'PEREZ',
      cdideper: 'AEPE880913902',
      cdperson: 122,
      cdprovin: null,
      cdtipide: '1',
      ciudad: null,
      clabe: null,
      codigoPostal: '90000',
      colonia: '001GX',
      domicilio: 'PEN',
      dsparentesco: 'TITULAR',
      email: 'TOMADOR@GMAIL.COM',
      email2: null,
      emailCliente: null,
      fechaNacimiento: '13/09/1988',
      fechaBaja: null,
      nacionalidad: '052',
      nexterior: '121',
      nmsituac: 1,
      nombre: 'ENRIQUE ASEGURADO PEREZ',
      nombre1: 'ENRIQUE',
      nombre2: null,
      nsuplogi: null,
      numeroFolio: null,
      pais: '052',
      provincia: '29',
      sexo: 'H',
      siglas: 'PI',
      telefono: '545555458',
      telefonoCliente: null,
      telefonoDomicilio: null,
      tipoIdentificacion: null,
      tipoPago: null,
    };
  }
  llenarPasoCuatro() {
    // this.arrayStep[3] = null;
  }

  cargarSelectsMaster() {
    this.apiBc.getBanks().subscribe(
      (response) => {
        this.bancos = response;
      },
      (error) => {
        console.log(error);
      }
    );
    this.apiBc.getNacionalidades().subscribe(
      (response) => {
        this.nacionalidades = response;
      },
      (error) => {
        console.log(error);
      }
    );
    this.apiBc.getSexo().subscribe(
      (response) => {
        this.sexo = response;
      },
      (error) => {
        console.log(error);
      }
    );

    this.apiBc.getSiglas().subscribe(
      (response) => {
        this.siglas = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  llenarStateBar() {
    let fecha;

    if (this.poliza.poliza_seleccionada.fechaUltimo != '-') {
      fecha = this.poliza.poliza_seleccionada.fechaUltimo;
    } else {
      fecha = null;
    }
    this.stateBar.data = [
      this.poliza.poliza_seleccionada.titular,
      this.poliza.poliza_seleccionada.nmpoliza,
      fecha,
    ];

    this.stateBar.stateTopBar = {
      name: 'vigente',
      fecha1: this.poliza.poliza_seleccionada.fechaInicio,
      fecha2: this.poliza.poliza_seleccionada.fechaFin,
    };
    this.stateBar.dataMobile = [this.poliza.poliza_seleccionada.nmpoliza, null];

    this.stateBar.headersTopBar = [
      'Titular',
      'Número de póliza',
      'Fecha de último pago',
    ];
  }
  seleccionarTramite(tipo) {
    this.asegurado.asegurado_seleccionado = null;
    this.siniestro.siniestro_seleccionado = null;
    this.beneficiario.ArrayBeneficiarios = [];
    this.beneficiario.Asegurado = null;
    if (tipo != 1 && tipo != 2) {
      this.beneficiario.isAsegurado = true;
    } else {
      this.beneficiario.isAsegurado = false;
    }
  }

  resetPasoUno() {
    this.poliza.listado_poliza = [];
    this.poliza.poliza_seleccionada = null;
    this.poliza.error = false;
    this.poliza.buscandoPoliza = false;
    this.poliza.msgError = null;
  }

  resetPasoDos() {
    this.tramite.tipos_tramite = [];
    this.tramite.tramite_seleccionado = null;
  }

  resetBeneficiario() {
    this.beneficiario = new Beneficiario();
    this.beneficiario.ArrayBeneficiarios = [];
    this.beneficiario.isAsegurado = true;
    this.beneficiario.switchAddBeneficiario = false;
  }

  resetPersonaContacto() {
    this.personaContacto = new PersonaContacto();
    this.personaContacto.isAsegurado = false;
    this.personaContacto.newContacto = false;
  }

  resetService() {
    this.cantSteps = 0;
    this.actualStep = 0;
    this.totalStep = 6;
    this.nmsiniestro = null;
    this.formAgente = null;

    this.arrayStep = [];
    this.bancos = null;
    this.nacionalidades = null;
    this.sexo = null;
    this.siglas = null;
    this.localidad = null;

    this.procesarPoliza = {
      polizasProcesadas: null,
      hasPolizas: false,
      createdTramite: null,
      nFolio: null,
      tramiteId: null,
    };

    this.tempFiles = [];
    this.tempPoliza = null;

    this.poliza = new Poliza();
    this.asegurado = new Asegurado();
    this.tramite = new Tramite();
    this.siniestro = new Siniestro();
    this.beneficiario = new Beneficiario();
    this.stateBar = new StateBar();
    this.validaciones = new Validaciones();
    this.personaContacto = new PersonaContacto();
    this.beneficiario.ArrayBeneficiarios = [];
    this.beneficiario.isAsegurado = true;
    this.beneficiario.switchAddBeneficiario = false;
    this.personaContacto.isAsegurado = false;
    this.personaContacto.newContacto = false;

    this.procesarPoliza.polizasProcesadas = [];

    this._baseURL = AppConstants.apiBienestar;

    if (this.project == 'monitor')
      this.arrayStep = [
        {
          ruta: '/vida/crear-tramite/listado-poliza',
          active: true,
        },
        {
          ruta: '/vida/crear-tramite/tipo-tramite',
          active: true,
        },
        {
          ruta: '/vida/crear-tramite/asegurado',
          active: true,
        },
        {
          ruta: '/vida/crear-tramite/siniestro-sub-secuente',
          active: true,
        },
        {
          ruta: '/vida/crear-tramite/beneficiario',
          active: true,
        },
        {
          ruta: '/vida/crear-tramite/persona-contacto',
          active: true,
        },
      ];
    else
      this.arrayStep = [
        {
          ruta: '/gestion-vida/listado-poliza',
          active: true,
        },
        {
          ruta: '/gestion-vida/tipo-tramite',
          active: true,
        },
        {
          ruta: '/gestion-vida/asegurado',
          active: true,
        },
        {
          ruta: '/gestion-vida/siniestro-sub-secuente',
          active: true,
        },
        {
          ruta: '/gestion-vida/beneficiario',
          active: true,
        },
        {
          ruta: '/gestion-vida/persona-contacto',
          active: true,
        },
      ];

    this.cargarSelectsMaster();
    this.calcularPasos();
    // this.llenarPasoUno();
    // this.llenarPasoDos(1);
    // this.llenarStateBar();
    // this.llenarPasoTres();
    // this.llenarPasoCuatro();

    // if (!this.poliza.poliza_seleccionada) {
    //   this.actualStep = 0;
    //   this.router.navigate(['/gestion-vida/listado-poliza']);
    // }
  }
}
