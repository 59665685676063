<app-header-bienestar [title]="''"></app-header-bienestar>

<navbar-blue [menu]="menu"> </navbar-blue>

<div class="internal-container">
  <breadcrumbs [breadcrumbs]="breadcrumbs"></breadcrumbs>

  <div style="text-align: center">
    <h2 class="title-one">Detalle de Trámite</h2>
  </div>

  <div class="section-principal">
    <div>
      <!-- <p>Detalle de Trámite</p> -->
      <p class="text-bold">
        Solic. {{ detalle?.nmsolicitud }} (Siniestro
        <span>{{ detalle?.siniestro }}</span> )
      </p>
      <p class="text-bold">Solicitado el {{ dateSolic }} - {{ tipoTramite }}</p>
    </div>
    <div
      class="text-right"
      style="display: flex; align-items: center; gap: 10px"
    >
      <div>
        <p
          [ngStyle]="{ color: detalle?.statusTramite.color }"
          class="text-medium text-bold text-uppercase"
        >
          <span>{{ detalle?.statusTramite.nombre }}</span>
        </p>
        <p>
          Actualizado al:
          {{ detalle?.statusTramite.fecha | date : "dd/MM/yyyy - h:mm:ss a" }}
        </p>
      </div>
    </div>
  </div>

  <div class="mt-2 text-right">
    <button
      class="btn btn-primary"
      style="height: auto; min-height: 32px"
      *ngIf="detalle?.botonFiniquito && !apiBusy && agente"
      (click)="generarFiniquito()"
    >
      Generar finiquito
    </button>

    <div *ngIf="apiBusy" class="mt-1" id="loading"></div>
  </div>

  <div
    class="tabsContainer mt-5"
    *ngIf="module != 'reembolso' && module != 'cirugia'"
  >
    <tabs [arrayTabs]="arrayTabs" [tam]="arrayTabs.length">
      <tab [tab]="arrayTabs[0]">
        <app-general [dataTimeLine]="detalle?.historial"></app-general>
      </tab>
    </tabs>
  </div>

  <div class="tabsContainer mt-5 tabsReembolso" *ngIf="module === 'reembolso'">
    <tabs [arrayTabs]="arrayTabs" [tam]="arrayTabs.length">
      <tab [tab]="arrayTabs[0]">
        <app-general [dataTimeLine]="detalle?.historial"></app-general>
      </tab>
      <tab [tab]="arrayTabs[1]">
        <app-facturas
          [idTramite]="idTramite"
          [detalle]="detalle"
        ></app-facturas>
      </tab>
      <tab [tab]="arrayTabs[2]">
        <app-info-pago
          *ngIf="cuentaBancaria"
          [infoBanco]="cuentaBancaria"
          [domicilio]="domicilio"
        ></app-info-pago>
      </tab>
      <tab [tab]="arrayTabs[3]">
        <app-contacto [contacto]="contacto"></app-contacto>
      </tab>
      <tab [tab]="arrayTabs[4]">
        <app-documentacion
          *ngIf="documentos"
          [documentos]="documentos"
          [module]="module"
          (updateTab)="tabActualizado($event)"
        >
        </app-documentacion>
      </tab>
    </tabs>
  </div>

  <div class="tabsContainer mt-5 tabsReembolso" *ngIf="module === 'cirugia'">
    <tabs [arrayTabs]="arrayTabs" [tam]="arrayTabs.length">
      <tab [tab]="arrayTabs[0]">
        <app-general [dataTimeLine]="detalle?.historial"></app-general>
      </tab>
      <tab [tab]="arrayTabs[1]">
        <app-contacto [contacto]="contacto"></app-contacto>
      </tab>
      <tab [tab]="arrayTabs[2]">
        <app-documentacion
          *ngIf="documentos"
          [documentos]="documentos"
          [module]="module"
          (updateTab)="tabActualizado($event)"
        >
        </app-documentacion>
      </tab>
    </tabs>
  </div>
</div>

<footer-sura></footer-sura>

<ngx-smart-modal
  #errorUploading
  identifier="errorUploading"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="errorUploading">Ocurrió un error al generar el finiquito.</div>

  <button
    class="btn btn-primary btn-ok mt-3"
    (click)="closeModal('errorUploading')"
  >
    OK
  </button>
</ngx-smart-modal>
