import { Component, OnInit } from '@angular/core';
import { ApiBienestarService } from '../service/api-bienestar.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(private apiBienestarService: ApiBienestarService) {}

  ngOnInit(): void {}

  goLogin() {
    window.location.href = this.apiBienestarService.loginURL;
  }
}
