import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/constants';
import { ApiBienestarService } from './service/api-bienestar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'GESTIÓN DE TRÁMITES';
  userLogged: boolean = false;

  constructor(
    public apiBienestarService: ApiBienestarService,
    private router: Router
  ) {
    localStorage.setItem('app', 'bienestar');
    this.apiBienestarService.loginURL = AppConstants.loginURL;
  }

  ngOnInit(): void {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let tk = url.searchParams.get('tk');
    let finiquito = url.searchParams.get('finiquito');

    console.log(tk);

    let tkAgente = url.searchParams.get('token');

    if (tkAgente) {
      localStorage.setItem('tkAgente', tkAgente);
      this.router.navigate(['/login-agente']);
      return;
    }

    if (tk != undefined && tk != null) {
      this.apiBienestarService.verifyToken(tk).subscribe(
        (response) => {
          this.apiBienestarService
            .getUserInfo('Bearer ' + response.access_token)
            .subscribe(
              (response2) => {
                this.apiBienestarService.setUser(response2.data);
                this.apiBienestarService.setToken(response.access_token);
                this.userLogged = true;
                this.router.navigate(['/home']);
              },
              (error) => {
                window.location.href = this.apiBienestarService.loginURL;
              }
            );
        },
        (error) => {
          window.location.href = this.apiBienestarService.loginURL;
        },
        () => {}
      );
    } else {
      let url = window.location.href.includes('portal-seguimiento');

      if (!url) {
        if (!this.apiBienestarService.getCurrentUser())
          window.location.href = this.apiBienestarService.loginURL;
        else {
          this.userLogged = true;
          if (!finiquito) this.router.navigate(['/home']);
        }
      }
      if (url) {
        this.userLogged = true;
      }
    }
  }
}
