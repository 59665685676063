<div class="container-bar">
  <div class="data" *ngFor="let item of header; let i = index">
    <p *ngIf="data[i]" class="text-bold data-p">
      {{ data[i] }}
    </p>
    <p *ngIf="!data[i]" class="text-bold data-p">N/D</p>
    <p>{{ item }}</p>
  </div>
  <div class="state" *ngIf="state">
    <p [ngStyle]="{ color: colorState }">
      {{ state.name | uppercase }}
    </p>
    <p>{{ state.vigencia }}</p>
  </div>
</div>
<div class="container-bar-mobile">
  <div class="header-bar">
    <p *ngIf="dataMobile[0]">
      Número de Póliza <span class="text-bold">{{ dataMobile[0] }}</span>
    </p>
    <p *ngIf="!dataMobile[0]">Número de Póliza ---</p>
  </div>
  <div class="body-bar">
    <div>
      <p>Tipo de Trámite</p>
      <p class="text-bold" *ngIf="dataMobile[1]">{{ dataMobile[1] }}</p>
      <p class="text-bold" *ngIf="!dataMobile[1]">----</p>
    </div>
    <div>
      <p class="text-xsmall" [ngStyle]="{ color: colorState }">
        {{ state.name | uppercase }}
      </p>
      <p>{{ state.vigencia }}</p>
    </div>
  </div>
</div>
