<div class="internal-container">
  <div class="main-title">
    <h2 class="title-one">Crea tu trámite</h2>
  </div>

  <h3 class="text-medium">
    Comencemos por ubicar el asegurado a quien desea <br />
    crearle la reclamación correspondiente
  </h3>

  <div class="search-container">
    <form [formGroup]="formFisica" *ngIf="filterBy == 'fisica'">
      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">Tipo de Persona</p>
          <select
            #select
            (change)="changeSelect(select.value)"
            formControlName="select"
            name="tipoPersona"
            class="form-control"
          >
            <option value="fisica">Persona Física</option>
            <option value="moral">Persona Moral</option>
          </select>
        </div>

        <div class="form-group">
          <p class="label">RFC</p>
          <input
            type="text"
            formControlName="rfc"
            class="form-control"
            style="text-transform: uppercase"
            onkeypress="return ((event.keyCode >= 48 && event.keyCode <=57) || (event.keyCode >=65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122)  || (event.keyCode == 209) || (event.keyCode == 241)) "
          />
          <small *ngIf="!f.rfc.valid && f.rfc.touched" class="ml-2 text-danger"
            >RFC no válido</small
          >
        </div>

        <div class="form-group">
          <p class="label">CURP</p>
          <input
            type="text"
            formControlName="curp"
            class="form-control"
            onkeypress="return ((event.keyCode >= 48 && event.keyCode <=57) || (event.keyCode >=65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122))"
          />
        </div>
      </div>

      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">Oficina</p>
          <input
            type="text"
            formControlName="oficina"
            class="form-control"
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
          />
        </div>

        <div class="form-group">
          <p class="label">Ramo</p>
          <select #ramo formControlName="ramo" name="ramo" class="form-control">
            <option
              *ngFor="let ramo of arrayRamos; let i = index"
              [value]="ramo"
            >
              {{ ramo }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <p class="label">Consecutivo de Póliza</p>
          <input
            type="text"
            formControlName="poliza"
            class="form-control"
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
          />
        </div>
      </div>

      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">Fecha de Ocurrencia (*)</p>
          <div style="margin-top: -6px">
            <app-date-picker
              [placeholder]="'dd-mm-aaaa'"
              [max]="toDay"
              [dateString]="dateString"
              (action)="getFechaOcurrencia($event)"
            ></app-date-picker>
          </div>

          <small
            *ngIf="
              filterBy == 'fisica' &&
              !f.fechaOcurrencia.valid &&
              f.fechaOcurrencia.touched
            "
            class="ml-2 text-danger"
            >Fecha de Ocurrencia no válida</small
          >
        </div>

        <div class="form-group">
          <p class="label">Primer Nombre (*)</p>
          <input
            type="text"
            formControlName="primerNombre"
            class="form-control"
            onkeypress="return ((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) || (event.keyCode == 32) ) "
          />
          <small
            *ngIf="!f.primerNombre.valid && f.primerNombre.touched"
            class="ml-2 text-danger"
            >Primer Nombre no válido</small
          >
        </div>

        <div class="form-group">
          <p class="label">Segundo Nombre</p>
          <input
            type="text"
            formControlName="segundoNombre"
            class="form-control"
            onkeypress="return ((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) || (event.keyCode == 32) ) "
          />
        </div>
      </div>

      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">Primer Apellido (*)</p>
          <input
            type="text"
            formControlName="primerApellido"
            class="form-control"
            onkeypress="return ((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) || (event.keyCode == 32) ) "
          />
          <small
            *ngIf="!f.primerApellido.valid && f.primerApellido.touched"
            class="ml-2 text-danger"
            >Primer Apellido no válido</small
          >
        </div>

        <div class="form-group">
          <p class="label">Segundo Apellido</p>
          <input
            type="text"
            formControlName="segundoApellido"
            class="form-control"
            onkeypress="return ((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode == 209) || (event.keyCode == 241) || (event.keyCode == 32) ) "
          />
        </div>

        <div class="form-group">
          <p class="label">Fecha de Nacimiento</p>
          <div style="margin-top: -6px">
            <app-date-picker
              [placeholder]="'dd-mm-aaaa'"
              [max]="toDay"
              [dateString]="dateString2"
              (action)="getFechaNacimiento($event)"
            ></app-date-picker>
          </div>

          <small
            *ngIf="!f.fechaNacimiento.valid && f.fechaNacimiento.touched"
            class="ml-2 text-danger"
          >
            Debe ser mayor de edad
          </small>
        </div>
      </div>
      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">Certificado</p>
          <input
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
            type="text"
            formControlName="certificado"
            class="form-control"
          />
        </div>
        <div class="form-group"></div>
        <div class="form-group"></div>
      </div>
    </form>

    <form [formGroup]="formMoral" *ngIf="filterBy == 'moral'">
      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">Tipo de Persona</p>
          <select
            #select
            (change)="changeSelect(select.value)"
            formControlName="select"
            name="tipoPersona"
            class="form-control"
          >
            <option value="fisica">Persona Física</option>
            <option value="moral">Persona Moral</option>
          </select>
        </div>
        <div class="form-group">
          <p class="label">Nombre de Empresa</p>
          <input
            type="text"
            formControlName="nombreEmpresa"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <p class="label">Fecha de Constitución (*)</p>
          <div style="margin-top: -6px">
            <app-date-picker
              [placeholder]="'dd-mm-aaaa'"
              [max]="toDay"
              [dateString]="dateString"
              (action)="getFechaConstitucion($event)"
            ></app-date-picker>
          </div>

          <small
            *ngIf="!m.fechaNacimiento.valid && m.fechaNacimiento.touched"
            class="ml-2 text-danger"
            >Fecha de constitución no válida</small
          >
        </div>
      </div>
      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">RFC</p>
          <input
            type="text"
            formControlName="rfc"
            class="form-control"
            style="text-transform: uppercase"
          />
          <small *ngIf="!m.rfc.valid && m.rfc.touched" class="ml-2 text-danger"
            >RFC no válido</small
          >
        </div>
        <div class="form-group">
          <p class="label">Oficina</p>
          <input
            type="text"
            formControlName="oficina"
            class="form-control"
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
          />
        </div>

        <div class="form-group">
          <p class="label">Ramo</p>
          <select #ramo formControlName="ramo" name="ramo" class="form-control">
            <option
              *ngFor="let ramo of arrayRamos; let i = index"
              [value]="ramo"
            >
              {{ ramo }}
            </option>
          </select>
        </div>
      </div>

      <div class="row multi-inputs">
        <div class="form-group">
          <p class="label">Consecutivo de Póliza</p>
          <input
            type="text"
            formControlName="poliza"
            class="form-control"
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
          />
        </div>
        <div class="form-group">
          <p class="label">Fecha de Ocurrencia (*)</p>
          <div style="margin-top: -6px">
            <app-date-picker
              [placeholder]="'dd-mm-aaaa'"
              [max]="toDay"
              [dateString]="dateString"
              (action)="getFechaOcurrencia($event)"
            ></app-date-picker>
          </div>
          <small
            *ngIf="
              filterBy == 'moral' &&
              m.fechaOcurrencia.touched &&
              !m.fechaOcurrencia.valid
            "
            class="ml-2 text-danger"
            >Fecha de Ocurrencia no válida</small
          >
        </div>
        <div class="form-group">
          <p class="label">Certificado</p>
          <input
            onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
            type="text"
            formControlName="certificado"
            class="form-control"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="noteForm">
    <p [ngClass]="{ 'text-danger': optionalValues == false }">
      <span class="text-bold">Nota:</span>
    </p>
    <ul>
      <li>
        <span
          >Debe completar al menos tres de los campos opcionales
          indicados.</span
        >
      </li>
      <li>
        <span
          >En caso de pólizas individuales el certificado deberá ser "1".</span
        >
      </li>
      <li>
        <span>La búsqueda puede demorar algunos minutos.</span>
      </li>
    </ul>
  </div>
  <div class="bottom">
    <div></div>
    <div *ngIf="!waitingForApi">
      <button
        class="btn btn-primary"
        *ngIf="filterBy == 'moral'"
        (click)="search()"
        [disabled]="formMoral.invalid"
      >
        consultar
      </button>
      <button
        class="btn btn-primary"
        *ngIf="filterBy == 'fisica'"
        (click)="search()"
        [disabled]="formFisica.invalid"
      >
        consultar
      </button>
    </div>

    <div *ngIf="waitingForApi" id="loading"></div>
  </div>

  <div *ngIf="showTable">
    <h4 *ngIf="!noDataError" class="text-medium">Seleccione una Póliza</h4>
    <app-table-singleselect
      [tableHeaders]="tableHeaders"
      [rows]="rows"
      [isSearching]="isSearching"
      [setSelected]="selected"
      (actionSelected)="manageAction($event)"
      class="table"
      [noDataError]="noDataError"
    ></app-table-singleselect>

    <div class="cards-container">
      <app-table-card
        [tableHeaders]="tableHeadersMobile"
        [rows]="rows"
        [setSelected]="selected"
        (actionSelected)="manageAction($event)"
        [isSearching]="isSearching"
        class="cards-container"
        [name]="'grupo1'"
        [noDataError]="noDataError"
      >
      </app-table-card>
    </div>

    <div class="bottom" style="padding: 30px 0">
      <div></div>

      <button
        class="btn btn-primary"
        [disabled]="selected === null || selected === undefined"
        (click)="continuar()"
        *ngIf="!noDataError"
      >
        continuar
      </button>
    </div>
  </div>
</div>

<!-- <button (click)="precargarForm()">precargarForm</button> -->
