<div class="info-bar">
  <div class="info-box text-bold">Facturas asociadas al trámite</div>
  <div class="info-box">
    <p
      class="text-bold"
      *ngIf="detalleFactura?.totalRequerido == 'NO DISPONIBLE'"
    >
      {{ detalleFactura?.totalRequerido }}
    </p>
    <p
      class="text-bold"
      *ngIf="detalleFactura?.totalRequerido != 'NO DISPONIBLE'"
    >
      ${{ detalleFactura?.totalRequerido | number : ".2" : "es" }}
    </p>
    <p>TOTAL REQUERIDO</p>
  </div>
  <div class="info-box">
    <p
      class="text-bold"
      *ngIf="detalleFactura?.totalAutorizado == 'NO DISPONIBLE'"
    >
      {{ detalleFactura?.totalAutorizado }}
    </p>
    <p
      class="text-bold"
      *ngIf="detalleFactura?.totalAutorizado != 'NO DISPONIBLE'"
    >
      ${{ detalleFactura?.totalAutorizado | number : ".2" : "es" }}
    </p>
    <p>TOTAL AUTORIZADO</p>
  </div>
  <div class="info-box">
    <p
      class="text-bold"
      *ngIf="detalleFactura?.totalDesviado == 'NO DISPONIBLE'"
    >
      {{ detalleFactura?.totalDesviado }}
    </p>
    <p
      class="text-bold"
      *ngIf="detalleFactura?.totalDesviado != 'NO DISPONIBLE'"
    >
      ${{ detalleFactura?.totalDesviado | number : ".2" : "es" }}
    </p>
    <p>TOTAL DESVIADO</p>
  </div>
</div>

<div class="bg-danger text-danger section-message" *ngIf="facturasDevueltas">
  <div class="section-icon">
    <i class="fas fa-exclamation-circle"></i>
  </div>
  <p>
    Una o más facturas han sido devueltos, por favor realice el envío
    actualizado de acuerdo con los motivos descritos. El proceso no podrá
    continuar hasta que no realice dicho envío.
  </p>
</div>

<div
  class="bg-green text-success section-message"
  *ngIf="detalle?.botoFiniquito"
>
  <div class="section-icon">
    <i class="fas fa-exclamation-circle"></i>
  </div>
  <p>
    Hemos terminado de procesar la facturación de su trámite, y puede descargar
    el finiquito correspondiente
  </p>
</div>

<div class="table-container">
  <app-table-basic-actions
    (action)="action($event)"
    [rows]="rows"
    [tableHeaders]="tableHeaders"
    [title]="' '"
    [type]="'tramites'"
    class="table"
    [stateType]="'estado'"
    [noDataError]="noDataError"
    [id]="'monitorCristales'"
    (eventActionColumn)="eventActionColumn($event)"
    [serverSidePagination]="false"
    [totalRows]="totalRows"
    (setPageEvent)="setPage($event)"
  ></app-table-basic-actions>

  <app-table-card-basic-actions
    [tableHeaders]="tableHeaders"
    [rows]="rows"
    class="cards-container"
    [name]="'grupo1'"
    [type]="'tramites'"
    (action)="action($event)"
    [actionColumn]="actionColumn"
    (eventActionColumn)="eventActionColumn($event)"
  >
  </app-table-card-basic-actions>
</div>

<div *ngIf="waitingForApi" class="mt-2">
  <div id="loading"></div>
</div>

<div *ngIf="!habilitarFiniquito">
  <button
    *ngIf="!waitingForApi"
    class="btn btn-primary continue"
    [disabled]="!validButtonFactura || !facturasValidadas.length != 0"
    (click)="subirFactura()"
  >
    Enviar Facturas Actualizadas
  </button>
</div>

<button
  *ngIf="detalle?.botoFiniquito"
  class="btn btn-primary continue"
  (click)="descargarFiniquito()"
>
  Descargar Finiquito
</button>

<ngx-smart-modal
  #confirmModal
  identifier="confirmModal"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="confirmModal">
    <div class="title text-bold">Confirmación</div>

    <p style="padding: 20px 0px">
      Al enviar la actualización de sus facturas no podrá hacer cambios hasta
      que uno de nuestros analistas realice la respectiva revisión. <br /><br />
      ¿Desea continuar?
    </p>
    <div class="img"></div>
  </div>
  <div class="buttons">
    <button
      class="btn btn-primary btn-danger"
      (click)="closeModal('confirmModal')"
    >
      Cancelar
      <!-- (click)="subirFactura()" -->
    </button>
    <button class="btn btn-primary btn-confirm" (click)="facturaActualizada()">
      Aceptar
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #confirmModal
  identifier="actualizadasModal"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="actualizadasModal">
    <div class="title text-bold">Facturas actualizadas</div>

    <p style="padding: 20px 0px">
      Se ha actualizado correctamente la información de facturación. Recibirá
      notificaciones de los cambios en él correo electrónico asociado al
      trámite.
    </p>
    <div class="img"></div>
  </div>
  <div class="buttons">
    <button
      class="btn btn-primary btn-confirm"
      (click)="closeModal('actualizadasModal')"
    >
      Aceptar
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #confirmModal
  identifier="motivoModal"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="motivoModal">
    <div class="title text-bold text-danger">
      Motivo de Devolución: {{ motivoDevolucion }}
    </div>

    <p style="padding: 20px 0px">
      Debe volver a enviar este archivo de acuerdo con el motivo y observaciones
      descritas en un plazo no mayor a 15 días para dar continuidad a su
      trámite.
    </p>

    <div class="title text-bold">Observaciones Adicionales</div>

    <p style="padding: 20px 0px">
      {{ observacionAdicional }}
    </p>
    <div class="img"></div>
  </div>
  <div class="buttons">
    <button
      class="btn btn-primary btn-confirm"
      (click)="closeModal('motivoModal')"
    >
      Aceptar
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #errorModal
  identifier="errorModal"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="errorModal">
    Por favor adjunte los documentos solicitados en el formato solicitado
  </div>
</ngx-smart-modal>

<input
  (change)="fileChangeEvent($event.target.files)"
  (click)="uploader.value = null"
  #uploader
  id="uploader"
  type="file"
  name="myImage"
/>

<ngx-smart-modal
  #errorModal
  identifier="errorModal"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="errorModal">
    <p>{{ errorFiniquito }}</p>

    <button class="btn btn-primary mt-4" (click)="closeModal('errorModal')">
      Ok
    </button>
  </div>
</ngx-smart-modal>
