import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-state-bar-vida',
  templateUrl: './state-bar-vida.component.html',
  styleUrls: ['./state-bar-vida.component.scss'],
})
export class StateBarVidaComponent implements OnInit {
  @Input() header: Array<string>;
  @Input() data: Array<string>;
  @Input() headerMobile: Array<string>;
  @Input() dataMobile: Array<string>;
  @Input() state: any;
  colorState: string;
  constructor() {
    this.state = {
      name: 'vigente',
      fecha1: '27/11/2008',
      fecha2: '27/11/2026',
    };
    this.header = ['Titular', 'Número de póliza', 'Fecha último pago'];
    this.data = ['Juan Ramírez Ramírez Ramírez', '9341', 'dd/mm/yy'];
  }

  ngOnInit(): void {
    console.log(this.header);
    console.log(this.data);
    console.log(this.headerMobile);
    console.log(this.dataMobile);
    console.log(this.state);

    if (this.state) {
      if (this.state.name === 'vigente') {
        this.colorState = '#00aec7';
      }
    }
  }
}
