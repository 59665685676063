<!-- <app-header-bienestar [title]="title"></app-header-bienestar> -->

<global-header [title]="title"></global-header>

<navbar-blue [menu]="menu"> </navbar-blue>
<div class="steps">
  <app-steps-bar
    [cantSteps]="vidaService.cantSteps"
    [actualStep]="vidaService.actualStep + 1"
  ></app-steps-bar>
</div>
<div
  class="stateBarContainer"
  *ngIf="vidaService.actualStep != 0 && vidaService.stateBar.data"
>
  <app-state-bar-vida
    [data]="vidaService.stateBar.data"
    [state]="vidaService.stateBar.stateTopBar"
    [header]="vidaService.stateBar.headersTopBar"
    [headerMobile]="['Número de poliza', 'Tipo de trámite']"
    [dataMobile]="vidaService.stateBar.dataMobile"
  ></app-state-bar-vida>
</div>

<div
  class="tramite-selected"
  *ngIf="vidaService.tramite.tramite_seleccionado && vidaService.actualStep > 1"
>
  <h4>
    Tipo de trámite:
    {{ vidaService.tramite.tramite_seleccionado.nombre | uppercase }}
  </h4>
</div>

<router-outlet></router-outlet>
<footer-sura></footer-sura>
