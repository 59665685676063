<header *ngIf="user">
  <div class="logo">
    <img src="assets/img/LogoSURA.svg" alt="" width="130" />
  </div>
  <h3 class="text-secondary">{{ title ? title : "GESTIÓN DE SINIESTROS" }}</h3>

  <div class="responsive-menu" id="responsive-menu">
    <span>{{ user.preferred_username }} </span>
    <img alt="" src="assets/img/user.png" (click)="switchDropdown()" />

    <div class="dropdown" id="dropdown" [ngClass]="{ active: showDropdown }">
      <p (click)="singOut()">Cerrar Sesión</p>
    </div>

    <div class="burger-menu" (click)="switchDropdownBurger()">
      <i class="fas fa-bars"></i>
      <div
        class="dropdown"
        id="dropdownBurger"
        [ngClass]="{ active: showDropdownBurger }"
      >
        <p>Dashboard</p>
        <p>Mis Trámites</p>
        <p>Confirmación</p>
        <p>Reportes</p>
      </div>
    </div>
  </div>
</header>
