import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiBienestarService } from '../../service/api-bienestar.service';

@Component({
  selector: 'app-portal-seguimiento',
  templateUrl: './portal-seguimiento.component.html',
  styleUrls: ['./portal-seguimiento.component.scss'],
})
export class PortalSeguimientoComponent implements OnInit {
  backgroundImage: string;
  state: number = 0;
  headers: Array<string>;
  dataTimeLine: Array<any>;
  typeTimeLine: Array<string>;
  actualizado: string;
  datosPortal: any;
  nmFolio: string;
  nmSiniestro: string;
  user: any;
  @Input() tramite: any;
  @Input() folioInput: any;
  canRender = true;
  colorLastStatus;
  menu: any;
  ocultar: boolean;

  type = 'reembolso';
  status = {
    info: 'Status',
    fecha: 'dd/mm/yy',
    hora: 'hh:mm:ss',
    color: 'azul',
  };
  header = [
    'Tipo de Trámite',
    'Número de Folio/Siniestro',
    'Fecha de Solicitud',
  ];
  data = ['Seguro de Vida', '1234567', '01/01/2000'];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private _apiBcService: ApiBienestarService,
    private _route: ActivatedRoute,
    public router: Router
  ) {
    this.backgroundImage = 'assets/img/fondo-auth.jpeg';
    this.headers = [
      'Tipo de Trámite',
      'Número de Folio',
      'Número de Siniestro',
    ];
    this.menu = [
      {
        menu: 'Portal de Seguimiento',
        routerLink: '/portal-seguimiento',
      },
    ];
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((params) => {
      this.nmFolio = params.get('nFolio');

      if (this.nmFolio) {
        this.canRender = false;
        this.consultar();
      }
    });
    if (this._apiBcService.getCurrentUser()) {
      this.ocultar = true;
    }
  }

  openModal() {
    this.ngxSmartModalService.getModal('portalModal').open();
  }

  closeModal() {
    this.ngxSmartModalService.getModal('portalModal').close();
  }

  consultar() {
    this._apiBcService.getPortal(this.nmFolio).subscribe(
      (response) => {
        console.log(response);
        this.datosPortal = response;
        this.data = [
          response.tipoTramite,
          response.nmsiniestro,
          response.fechaSolicitud,
          response.nroFolio,
        ];

        this.nmSiniestro = this.datosPortal.nmsiniestro
          ? this.datosPortal.nmsiniestro
          : '';
        if (this.nmSiniestro == null || this.nmSiniestro == '') {
          this.nmSiniestro = '';
        } else {
          this.nmSiniestro = '/' + String(this.nmSiniestro);
        }

        let auxInfo;
        if (response.statusTramite.nombre.length > 14) {
          auxInfo = response.statusTramite.nombre.substring(0, 15) + '...';
        } else {
          auxInfo = response.statusTramite.nombre;
        }
        this.status = {
          info: auxInfo,
          fecha: response.statusTramite.fecha.split(' ')[0],
          hora: response.statusTramite.fecha.split(' ')[1],
          color: response.statusTramite.color.toLowerCase(),
        };
        this.status.info = this.status.info ? this.status.info : 'N/A';

        this.dataTimeLine = response.historial;
        this.state = 1;
        this.canRender = true;

        this.colorLastStatus = response.statusTramite.color.toLowerCase();
      },
      (error) => {
        this.ngxSmartModalService.getModal('portalModal').open();
      }
    );
  }

  toHome() {
    this.state = 0;
    this.nmFolio = '';
    if (this.user) this.router.navigate(['/home']);
    else this.router.navigate(['/portal-seguimiento']);
  }

  logout() {
    window.location.href = 'http://loginfront.azurewebsites.net';
  }
}
