import { Component, OnInit } from '@angular/core';
import { ActionTableBasic } from 'src/app/shared/tables/table-basic-actions/action.interface';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-print-finiquito',
  templateUrl: './print-finiquito.component.html',
  styleUrls: ['./print-finiquito.component.scss'],
})
export class PrintFiniquitoComponent implements OnInit {
  selectEstado: any = [];
  arrayEstado: any[] = [];
  selectedAsignada: any = [];
  selectedEstado: any = [];
  selectAsignada: any = [];
  desde: any;
  hasta: any;
  clearDP: boolean;
  rows: any = [];
  arrayFacturas: any = [];
  facturas = [];
  listIndicadores: any[] = [];
  actionColumn: Array<ActionTableBasic> = [];
  rangeDate1: Date;
  rangeDate2: Date;
  validDate1: boolean;
  validDate2: boolean;
  getDateFrom: any;
  getDateTo: any;
  dateString1: any;
  dateString2: any;
  today: Date;
  arrayDictaminadoresReembolso = [];
  cristalSiniestroId;
  reasigned = false;

  noDataError: boolean;
  nombreOriginal: string;
  reasignarTo: any;
  totalRows;
  nestedFilterActive = false;
  buscarString = '';
  detalle;
  idTramite;
  finiquitos;

  myAngularxQrCode: string = null;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public route: Router
  ) {
    this.finiquitos = JSON.parse(localStorage.getItem('finiquito')).finiquitos;
    this.idTramite = JSON.parse(localStorage.getItem('idTramite'));
    // localStorage.removeItem('finiquito');
    // localStorage.removeItem('idTramite');

    let url = window.location.href;
    let hostname;

    if (window.location.href.indexOf('//') > -1) {
      hostname = url.split('/')[0] + '//' + url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    // this.myAngularxQrCode =
    //   hostname + '/gmm/dashboard/reembolso/detalle-tramite/' + this.idTramite;

    this.myAngularxQrCode = `${environment.bienestarURL}/portal-seguimiento/`;
  }

  ngOnInit(): void {
    registerLocaleData(es);

    document.title = 'FiniquitosGenerados.pdf';

    window.print();

    window.onafterprint = function () {
      window.close();
      document.title = 'Monitor-BC';
    };
  }
}
