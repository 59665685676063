import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from './menu.model';

@Component({
  selector: 'bnr-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() menu: MenuItem[] = [];
  selected: MenuItem;
  showDropdownBurger: boolean = false;

  @Output() eventEmitter = new EventEmitter<String>();

  constructor() {}

  ngOnInit(): void {
    console.log(this.menu);
    this.selected = this.menu[0];
    this.dropDownListenerBurger();
  }

  action(item) {
    this.selected = item;
    this.eventEmitter.emit(item.action);
  }

  showBurgerMenu() {
    console.log('menu');
  }

  switchDropdownBurger() {
    this.showDropdownBurger = !this.showDropdownBurger;
  }

  dropDownListenerBurger() {
    document.addEventListener('click', (evt) => {
      const dropdown = document.getElementById('dropdownBurger');
      let targetElement: any = evt.target;

      if (targetElement.closest('.burger-menu')) return;

      do {
        if (targetElement == dropdown) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      // console.log("Clicked outside!");
      this.showDropdownBurger = false;
    });
  }
}
