<div class="detalle-container">
  <div class="section">
    <p class="text-bold text-large mb-4">
      Información bancaria asociada al trámite
    </p>

    <div class="row-label">
      <div>
        <p class="text-bold text-medium">{{ infoBanco.rfc }}</p>
        <p>RFC</p>
      </div>
      <div>
        <p class="text-bold text-medium">
          {{ infoBanco.nombre1 }} {{ infoBanco.nombre2 }}
          {{ infoBanco.apellido1 }} {{ infoBanco.apellido2 }}
        </p>
        <p>NOMBRE COMPLETO</p>
      </div>
      <div>
        <p class="text-bold text-medium">{{ infoBanco.nacionalidadDesc }}</p>
        <p>NACIONALIDAD</p>
      </div>
      <div>
        <p class="text-bold text-medium" *ngIf="infoBanco.sexo == 'H'">
          Masculino
        </p>
        <p class="text-bold text-medium" *ngIf="infoBanco.sexo == 'M'">
          Femenino
        </p>
        <p class="text-bold text-medium" *ngIf="!infoBanco.sexo">-</p>
        <p>SEXO</p>
      </div>
    </div>
    <div>
      <p class="text-medium mb-3">
        <span class="text-bold"
          >{{ infoBanco.tipoMoneda }} - {{ infoBanco.clabe }}</span
        >
        /{{ infoBanco.bancoDesc }}
        <span> (Tipo de Pago: Transferencia)</span>
      </p>
      <div *ngIf="infoBanco.archivos">
        <file-manager
          *ngFor="let item of arrayDocs; let i = index"
          [fileInfo]="item.fileInfo"
          [status]="item.status"
          [colorStatus]="item.colorStatus"
          [index]="i"
          [first]="i === 0 ? true : false"
          [actions]="item.actions"
          (fileManagerEvent)="fileManagerEvent($event, i)"
        >
        </file-manager>
      </div>
    </div>

    <p class="text-bold text-large mt-4 mb-4">Información del Domicilio</p>
    <div class="sectionDomicilio">
      <div class="row-label">
        <div>
          <p class="text-bold text-medium">
            {{
              !domicilio?.siglas || domicilio?.siglas === " "
                ? "N/D"
                : domicilio.siglas
            }}
          </p>
          <p>SIGLAS</p>
        </div>
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.domicilio ? domicilio?.domicilio : "N/D" }}
          </p>
          <p>DOMICILIO</p>
        </div>
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.nmexterior ? domicilio?.nmexterior : "N/D" }}
          </p>
          <p>NÚMERO EXTERIOR</p>
        </div>
      </div>

      <div class="row-label mt-3">
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.nminterior ? domicilio?.nminterior : "N/D" }}
          </p>
          <p>NÚMERO INTERIOR</p>
        </div>
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.cdpostal ? domicilio?.cdpostal : "N/D" }}
          </p>
          <p>CÓDIGO POSTAL</p>
        </div>
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.pais ? domicilio?.pais : "N/D" }}
          </p>
          <p>PAÍS</p>
        </div>
      </div>

      <div class="row-label mt-3">
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.estado ? domicilio?.estado : "N/D" }}
          </p>
          <p>ESTADO</p>
        </div>

        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.municipio ? domicilio?.municipio : "N/D" }}
          </p>
          <p>MUNICIPIO</p>
        </div>
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.ciudad ? domicilio?.ciudad : "N/D" }}
          </p>
          <p>CIUDAD</p>
        </div>
      </div>

      <div class="row-label mt-3">
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.colonia ? domicilio?.colonia : "N/D" }}
          </p>
          <p>COLONIA</p>
        </div>
        <div>
          <p class="text-bold text-medium">
            {{ domicilio?.email ? domicilio?.email : "N/D" }}
          </p>
          <p>CORREO ELECTRÓNICO</p>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</div>
