<h3 class="text-bold text-medium mb-3 mt-3">Información de Domicilio</h3>
<div *ngIf="showTable && asegurado" class="tableContainer">
  <app-table-singleselect
    [tableHeaders]="tableHeaders"
    [rows]="rows"
    [isSearching]="isSearching"
    [setSelected]="selected"
    (actionSelected)="manageAction($event)"
    class="table"
    [noDataError]="noDataError"
  ></app-table-singleselect>
  <div class="cards-container">
    <app-table-card
      [tableHeaders]="tableHeaders"
      [rows]="rows"
      [setSelected]="selected"
      (actionSelected)="manageAction($event)"
      [isSearching]="isSearching"
      class="cards-container"
      [name]="'grupo1'"
      [noDataError]="noDataError"
    >
    </app-table-card>
  </div>
  <div class="text-secondary mb-3 agregarDomicilio" (click)="showHideTable()">
    Agregar Domicilio
  </div>
</div>
<div *ngIf="showForm">
  <form [formGroup]="formDomicilio">
    <div class="row multi-inputs">
      <div class="form-group">
        <label>Siglas (*)</label>

        <select class="form-control" formControlName="siglas">
          <option value="" selected>Selecciona Sigla</option>
          <option
            *ngFor="let sigla of arraySiglas; let i = index"
            [value]="sigla.code"
          >
            {{ sigla.label }}
          </option>
        </select>
        <small
          *ngIf="!f.siglas.valid && f.siglas.touched"
          class="ml-2 text-danger"
          >Sigla no válida</small
        >
      </div>
      <div class="form-group">
        <label for="domicilio">Domicilio (*)</label>
        <input type="text" class="form-control" formControlName="domicilio" />
        <small
          *ngIf="!f.domicilio.valid && f.domicilio.touched"
          class="ml-2 text-danger"
          >Domicilio no válido</small
        >
      </div>

      <div class="form-group">
        <label>Número Exterior (*)</label>
        <input
          required
          type="text"
          class="form-control"
          formControlName="nmexterior"
        />
        <small
          *ngIf="!f.nmexterior.valid && f.nmexterior.touched"
          class="ml-2 text-danger"
          >Número externo no válido</small
        >
      </div>
    </div>

    <div class="row multi-inputs">
      <div class="form-group">
        <label>Número Interior</label>
        <input type="text" class="form-control" formControlName="nminterior" />
        <!-- <small
                *ngIf="!numero_interno.valid && numero_interno.touched"
                class="ml-2 text-danger"
                >Número interno no válido</small
              > -->
      </div>

      <div class="form-group">
        <label>Código Postal (*)</label>

        <input
          (focusout)="changueCodigo()"
          formControlName="cdpostal"
          maxlength="5"
          type="text"
          class="form-control"
          name="cdpostal"
          onkeypress="return (event.keyCode != 32 && ( (event.keyCode >= 48) && (event.keyCode <=57) ))"
        />
        <small
          *ngIf="!f.cdpostal.valid && f.cdpostal.touched"
          class="ml-2 text-danger"
          >Código postal no válido</small
        >
      </div>
      <div class="form-group">
        <label>País (*)</label>
        <input formControlName="pais" type="text" class="form-control" />
        <!-- <small *ngIf="!pais.valid && pais.touched" class="ml-2 text-danger"
                >País no válido</small
              > -->
      </div>
    </div>

    <div class="row multi-inputs">
      <div class="form-group">
        <label>Estado (*)</label>
        <input
          type="text"
          formControlName="estado"
          class="form-control"
          name="provincia"
        />
        <!-- <small
                *ngIf="!provincia.valid && provincia.touched"
                class="ml-2 text-danger"
                >Provincia no válida</small
              > -->
      </div>
      <div class="form-group">
        <label>Municipio (*)</label>
        <input formControlName="municipio" type="text" class="form-control" />
        <!-- <small
                *ngIf="!municipio.valid && municipio.touched"
                class="ml-2 text-danger"
                >Municipio no válido</small
              > -->
      </div>
      <div class="form-group">
        <label>Ciudad (*)</label>
        <input formControlName="ciudad" type="text" class="form-control" />
        <!-- <small *ngIf="!ciudad.valid && ciudad.touched" class="ml-2 text-danger"
                >Ciudad no válida</small
              > -->
      </div>
    </div>

    <div class="row multi-inputs">
      <div class="form-group">
        <label>Colonia (*)</label>
        <!-- (ngModelChange)="saveCodigoPostal()" -->
        <select formControlName="colonia" class="form-control" required>
          <option
            *ngFor="let colonia of arrayColonias; let i = index"
            [value]="colonia.code"
          >
            {{ colonia.desc }}
          </option>
        </select>
        <small
          *ngIf="!f.colonia.valid && f.colonia.touched"
          class="ml-2 text-danger"
          >Colonia no válida</small
        >
      </div>
      <div class="form-group">
        <label>Correo electrónico (*)</label>
        <input type="email" class="form-control" formControlName="email" />
        <small
          *ngIf="!f.email.valid && f.email.touched"
          class="ml-2 text-danger"
          >Correo electrónico no válido</small
        >
      </div>
      <div class="form-group"></div>
    </div>
  </form>
  <div
    *ngIf="asegurado"
    class="text-secondary mb-3 agregarDomicilio"
    (click)="showHideTable()"
  >
    Seleccionar un domicilio existente
  </div>
</div>
