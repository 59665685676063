<div class="navbar">
  <div
    class="item menu"
    *ngFor="let item of menu"
    (click)="action(item)"
    [ngClass]="{ active: selected === item }"
  >
    <i [class]="item.icon"></i><span>{{ item.label }} </span>
  </div>
</div>

<div class="navbar-mobile">
  <div class="current">
    <span> {{ selected.label }}</span>
  </div>
  <div class="burger-menu" (click)="switchDropdownBurger()">
    <i class="fas fa-bars"></i>
    <div
      class="dropdown"
      id="dropdownBurger"
      [ngClass]="{ active: showDropdownBurger }"
    >
      <p class="item menu" *ngFor="let item of menu" (click)="action(item)">
        {{ item.label }}
      </p>
    </div>
  </div>
</div>
