import { Historial } from './../../../../vida/models/detalle-tramite';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBienestarService } from 'projects/bienestar/src/app/service/api-bienestar.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit {
  @Input() dataTimeLine;
  constructor() {}

  ngOnInit(): void {}
}
