<div class="navbar">
  <div
    #menu1
    (click)="switchMenu(menu1, menu5, 'principal')"
    class="item menu active"
  >
    <i class="fas fa-home"></i><span>Principal</span>
  </div>
  <!-- <div #menu2 (click)="switchMenu(menu2, menu6, 'tramites')" class="item menu"><i class="fas fa-list"></i><span>Mis Trámites</span></div> -->
  <div #menu3 (click)="switchMenu(menu3, menu7, 'datos')" class="item menu">
    <i class="fas fa-user"></i><span>Mis Datos</span>
  </div>
  <div
    #menu4
    (click)="switchMenu(menu4, menu8, 'documentos')"
    class="item menu"
  >
    <i class="fas fa-file"></i><span>Documentos</span>
  </div>
</div>

<div class="navbar-sm">
  <div
    #menu5
    (click)="switchMenu(menu5, menu1, 'principal')"
    class="item menu active"
  >
    <i class="fas fa-home"></i><span>Principal</span>
  </div>

  <div #menu7 (click)="switchMenu(menu7, menu3, 'datos')" class="item menu">
    <i class="fas fa-user"></i><span>Datos</span>
  </div>
  <div
    #menu8
    (click)="switchMenu(menu8, menu4, 'documentos')"
    class="item menu"
  >
    <i class="fas fa-file"></i><span>Documentos</span>
  </div>
</div>
