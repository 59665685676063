<div class="tab-container">
  <p class="text-bold text-medium">Documentos asociados al trámite</p>

  <div *ngIf="docDevuelto" class="bg-danger text-danger section-message mt-3">
    <i class="fas fa-exclamation-circle mr-2"></i>
    <p>
      Uno o más documentos han sido devueltos, por favor realice el envio
      actualizado de acuerdo con los motivos descritos. El proceso no podrá
      continuar hasta que no realice dicho envío.
    </p>
  </div>
  <div class="mt-3">
    <file-manager
      *ngFor="let item of arrayDocs; let i = index"
      [fileInfo]="item.fileInfo"
      [status]="item.status"
      [colorStatus]="item.colorStatus"
      [first]="i === 0"
      [vigencia]="item.estadoVigencia"
      [actions]="item.actions"
      [index]="i"
      [motivo]="item.motivoDevolucion"
      [adicional]="item.motivoAdicional"
      (fileManagerEvent)="fileManagerEvent($event, i)"
    >
      <div details-return class="reason" *ngIf="item.status == 'DEVUELTO'">
        <div class="container-motivo">
          <div class="section-motivo">
            <div class="text-left w-70">
              <p class="text-bold">
                Debe volver a enviar este archivo de acuerdo con el motivo y
                observaciones descritas en un plazo no mayor a 15 días para dar
                continuidad a su trámite.
              </p>
            </div>
            <div class="text-center w-30">
              <p *ngIf="item.motivoDevolucion" class="text-bold text-danger">
                Motivo de Devolucion: {{ item.motivoDevolucion }}
              </p>
              <p
                *ngIf="item.motivoAdicional && !item.motivoDevolucion"
                class="text-bold text-danger"
              >
                Motivo adicional: {{ item.motivoAdicional }}
              </p>
            </div>
          </div>
          <div class="mt-3">
            <p class="text-bold">Observaciones Adicionales</p>
            <p class="mt-2">{{ item.obserAdicional }}</p>
          </div>
        </div>
      </div>
    </file-manager>
  </div>

  <button
    [disabled]="!band"
    (click)="openModalDevo()"
    *ngIf="!apiBusy"
    class="btn btn-primary mt-5"
  >
    guardar y enviar
  </button>

  <div class="mt-4" id="loading" *ngIf="apiBusy"></div>
</div>

<!-- MODALES -->
<ngx-smart-modal
  #confirDevolucionReembolso
  identifier="confirDevolucionReembolso"
  [customClass]="'nsm-centered modal-acept-contrac'"
  class="text-center"
>
  <div class="modal-icon">
    <i class="fas fa-check-circle"></i>
  </div>
  <div class="container-modal mt-3">
    <div class="mb-3">
      <strong>Documentación actualizada</strong>
      <p class="mt-2">
        Se ha actualizado correctamente la documetación indicada. Recibirá
        notificaciones de los cambios en él correo electrónico asociado al
        trámite.
      </p>
    </div>
    <button (click)="closeModalDevo()" class="btn btn-primary">aceptar</button>
  </div>
  <div></div>
</ngx-smart-modal>

<ngx-smart-modal
  #errorUploading
  identifier="errorUploading"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div class="errorUploading">
    Por favor adjunte los documentos solicitados en el formato solicitado
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #ErrorModal
  identifier="ErrorModal"
  [customClass]="'nsm-centered'"
  class="text-center"
>
  <div>
    <p>{{ msjError }}</p>
  </div>
</ngx-smart-modal>
