import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-bienestar',
  templateUrl: './navbar-bienestar.component.html',
  styleUrls: ['./navbar-bienestar.component.scss'],
})
export class NavbarBienestarComponent implements OnInit {
  menuSelected = 'principal';
  constructor() {}

  ngOnInit(): void {}

  switchMenu(menu, menu2, menuSelected) {
    this.menuSelected = menuSelected;

    let menus = document.getElementsByClassName('menu');

    for (let index = 0; index < menus.length; index++) {
      const element = menus[index];
      element.classList.remove('active');
    }
    menu.classList.add('active');
    menu2.classList.add('active');
  }
}
